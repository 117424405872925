import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";

import { Container, Row, Col, Card, ListGroup, Button, Form, Spinner, Alert, Table } from 'react-bootstrap';
import axios from 'axios';
import {BASE_URL} from '../constants';
import { components } from 'react-select';
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
function AdminPortal() {
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [spinner, setSpinner] = useState(false);

  // const [recruiters, setRecruiters] = useState([
  //   { id: 1, name: 'Hara priya', consultants: [{ id: 1, name: 'shiva', email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey', technology:'java'},{ id: 1, name: 'shiva', email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey', technology:'.net'}] },
  //   { id: 2, name: 'Tejaswini', consultants: [{ id: 1, name: 'shiva',email: 'test@gmail.com', phone: '559-917-6123', location: 'New jersey',technology:'.net'}]},
  //   { id: 3, name: 'Tejaswini', consultants: [{ id: 1, name: 'Hara priya', email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey',technology:'java'}]},
  //   { id: 4, name: 'Tejaswini', consultants: [{ id: 1, name: 'Hara priya', email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey',technology:'java'}]},
  //   { id: 5, name: 'Tejaswini', consultants: [{ id: 1, name: 'Hara priya',email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey',technology:'java'}]},
  //   { id: 6, name: 'Tejaswini', consultants: [{ id: 1, name: 'Hara priya',email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey',technology:'java'}]},
  //   { id: 7, name: 'Tejaswini', consultants: [{ id: 1, name: 'Hara priya', email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey',technology:'java'}]},
  //   // Add more recruiters here as needed
  // ]);
  const [recruiters, setRecruiters] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [recAndCons, setRecAndCons] = useState([]);
  // const [consultants, setConsultants] = useState([
  //   { id: 1, name: 'shiva', email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey', technology:'java'},
  //   { id: 2, name: 'esther', email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey', technology:'java'},
  //   { id: 3, name: 'esha', email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey', technology:'java'},
  //   // Add more recruiters here as needed
  // ]);
  const [selectedRecruiterId, setSelectedRecruiterId] = useState(null);
  const [newConsultantId, setNewConsultantId] = useState(null);
  const [technology, setTechnology] = useState(null);
  const [error, setError] = useState('');

  const [data, setData] = useState([]);

  const [isDeleting, setIsDeleting] = useState(false);

  const [loginActivity, setLoginActivity] = useState([]);


  const company = sessionStorage.getItem('company');


    useEffect(() => {
      const fetchData =  () => {
        try {
          const response =  axios.get(BASE_URL+`/users/${company}`);
           response.then(response => {
           // console.log(response.data);
            const recs= response.data.filter((item) => item.role === 'RECRUITER' );
            const consul= response.data.filter((item) => item.role === 'CONSULTANT' );
            const updatedRecs= [...recruiters, ...recs];
           // console.log('recs', updatedRecs);
            setRecruiters([...updatedRecs]);
            // setRecAndCons([...updatedRecs]);
            setConsultants(consul);
             setLoading(false);

           });
        } catch (err) {
          setError(err);
         
        }
      };

      const fetchRecruiters = async () => {
        try {
          const response = await axios.get(BASE_URL+`/recruiters/${company}`);
          console.log('recs data', response.data);
        
          setRecAndCons(response.data);
          console.log('recs and consul',response.data);
        } catch (err) {
          setError(err);
         
        }
      };
     
       
      fetchData();
      fetchRecruiters();
      fetchLoginActivity();
    }, []);
  

    console.log('recruiters', recruiters);


//   const [selectedConsultantId, setSelectedConsultantId] = useState(null);

  const handleAddConsultant1 = () => {
    if (!newConsultantId || selectedRecruiterId === null) return;
 // Check if the consultant name already exists for the selected recruiter
 const recruiter = recruiters.find((r) => r.id === selectedRecruiterId);
 if (recruiter?.consultants.includes(newConsultantId)) {
   setError('Consultant with the same name already exists.');
   return;
 }
    setRecruiters((prevRecruiters) => {
      const updatedRecruiters = [...prevRecruiters];
      const recruiter = updatedRecruiters.find((r) => r.id === selectedRecruiterId);

      if (recruiter) {
       console.log(newConsultantId);
      
        const foundItem = consultants.find(item => item.id === newConsultantId);
        console.log(foundItem);
      recruiter.consultants.push(foundItem);
      }

      return updatedRecruiters;
    });

    setNewConsultantId('');
    setError('');
  };
  const handleAddConsultant = () => {
    setSpinner(true);
    const foundItem = consultants.find((person) => person.id === newConsultantId);

    // Define the request body with consultantid, recruitid, and technology
    const requestBody = {
      consultantid: newConsultantId, // Replace with the desired consultant ID
      recruitid: selectedRecruiterId,    // Replace with the desired recruit ID
      technology: foundItem.technology, // Replace with the desired technology
      company: company,
    };

    setTimeout(async () => {
    // Make a POST request to the API
    try{
      axios.post(BASE_URL+"/addConsultant", requestBody)
      .then((response) => {
        console.log('Consultant added successfully:', response.data);
        setSpinner(false);

        const fetchRecruiters = async () => {
          try {
            const response = await axios.get(BASE_URL+`/recruiters/${company}`);
            console.log(response.data);
            console.log('hello#########',recruiters);
            setRecAndCons(response.data);
            console.log(recAndCons);
          } catch (err) {
            setError(err);
           
          }
        };
    
        fetchRecruiters();



      })
      .catch((error) => {
        setSpinner(false);
        console.error('Error adding consultant:', error.message);
      });
    }catch(err){

    }
  
   
    },500);

  };
  const handleDeleteConsultant = (recruiterid, consultantIndex, consultantid) => {
    // setRecAndCons((prevRecruiters) => {
    //   const updatedRecruiters = [...prevRecruiters];
    //   const recruiter = updatedRecruiters.find((r) => r.id === recruiterId);

    //   if (recruiter) {
    //     recruiter.consultants.splice(consultantIndex, 1);
    //   }

    //   return updatedRecruiters;
    // });

    try {
      // Make a DELETE request to the API endpoint
       axios.delete(BASE_URL+`/recruitandconsultant/${consultantid}/${recruiterid}/${company}`);
      // Item deleted successfully
      console.log('Item deleted successfully');
      setRecAndCons((prevRecruiters) => {
        const updatedRecruiters = [...prevRecruiters];
        const recruiter = updatedRecruiters.find((r) => r.id === recruiterid);
      console.log(recruiter);
        if (recruiter) {
          //recruiter.consultants.filter((consultant) => consultant.id !== consultantid);
   // setConsultants(updatedConsultants);
       //  recruiter.consultants.splice(consultantIndex, 1);
         recruiter.consultants = recruiter.consultants.filter((consultant) => consultant.id !== consultantid);

        }
  
        return updatedRecruiters;
      });
    } catch (error) {
      // Handle error, e.g., show an error message to the user
      console.error('Error deleting item:', error);
    }



  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    setLoading(true);
    setTimeout(async () => {
      try{
        axios.post(BASE_URL+"/upload", formData).then((response) => {
          console.log(response.data);
          setSuccessMessage(response.data.message);
          setLoading(false);
        });
      }catch(err){
        setLoading(false);
        setError(true);
      }finally {
        setLoading(false); // Hide loader when data is received (whether success or error)
      }
    },500);

    
  };

  const handleDeleteRecruiter = (recruiterid) => {
    // Prepare the request data
    const requestData = { active: 0 , company : company}; // Toggle the active flag
    setSpinner(true);

    // Make the API call using Axios
    axios
      .put(`${BASE_URL}/updateActiveFlag/${recruiterid}`, requestData)
      .then((response) => {
        console.log(response.data.message);
        const filteredRecs = recAndCons.filter((item) => item.id != recruiterid);
       // Update the local state
       setSpinner(false);

       setRecAndCons(filteredRecs);
       setSuccessMessage(response.data.message);
      })
      .catch((error) => {
        setSpinner(false);

        setError('Unable to remove the recruiter.please try agin later.');
        console.error('API call failed:', error);
      });
  };
  function convertUTCtoEST(utcTimestamp) {
    // Create a Date object from the UTC timestamp
    const utcDate = new Date(utcTimestamp);
  
    // Get the UTC time in milliseconds
    const utcTimeMilliseconds = utcDate.getTime();
  
    // EST is UTC - 5 hours
    const estTimeMilliseconds = utcTimeMilliseconds - 5 * 60 * 60 * 1000;
  
    // Create a new Date object for the EST time
    const estDate = new Date(estTimeMilliseconds);
  
    return estDate;
  }

  const formatDateToEST = (utcTimestamp) => {
    // Your logic to convert UTC to EST and format the date as a string
    // For example:
    const estDate = new Date(utcTimestamp);
    return estDate.toLocaleString('en-US', { timeZone: 'America/New_York' });
  };

  const fetchLoginActivity = async () => {
    
    try {
      const response = await axios.get(`${BASE_URL}/getLoginActivity?company=${company}`);

      const loginActivityWithEST = response.data.map((activity) => {
       // const estTimestamp = formatDateToEST(activity.timestamp);        
        const estTimestamp = activity.timestamp;

        return {
          ...activity,
          timestamp: estTimestamp,
        };
      });

      setLoginActivity(loginActivityWithEST);
    } catch (error) {
      console.error('Error fetching login activity:', error);
    }
  }
  return (

    
    <div>

{loading ? (
      <p>Loading...</p>
    ) :(
     
    <Container>
    
      <Row>
        <Col md={4}>
       
          <h2>Bench sales Recruiters</h2>
          {recruiters.length === 0 && <p>No data to display</p>}
          {recAndCons.length> 0 && recAndCons?.map((recruiter) => (
            <Card key={recruiter.id} className="bg-info mb-3">
                      <Card.Header>
                <span><strong>{recruiter.name}</strong></span>
                <Button
                  variant="secondary"
                  size="sm"
                  className="float-right"
                  onClick={() => handleDeleteRecruiter(recruiter.id)}
                >
                  <i className="bi bi-trash"></i> 
                </Button>

                {/* <Button variant="secondary"
                  size="sm"
                  className="float-right"
                  onClick={() => handleDeleteRecruiter(recruiter.id)}>
          {spinner ? (
            <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
            </>
          ) : (
            <i className="bi bi-trash"></i>
          )}
        </Button> */}
              </Card.Header>
      
              <ListGroup variant="flush">
                {recruiter?.consultants?.map((consultant, index) => (
                  <ListGroup.Item key={index}>
                    {consultant.name} | {consultant.email} | {consultant.phone} |  {consultant.location} | {consultant.technology} 
                    <Button
                      variant="danger"
                      size="sm"
                      className="float-right"
                      onClick={() => handleDeleteConsultant(recruiter.id, index, consultant.id)}
                    >
                      Delete
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          ))}
        </Col>
        <Col md={4}>
        <div>
      {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
    </div>
        <div className="container mt-4">
      <h2>Upload New Recruiters</h2>
      <input type="file" onChange={handleFileChange} />
      {/* <button className="btn btn-dark mt-2" onClick={handleUpload}>
        Upload
      </button> */}
      <button className="btn btn-info mt-2" onClick={handleUpload}>
          {loading ? (
            <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
            </>
          ) : (
            'upload'
          )}
        </button>
    </div>
          <h2>Add Consultant</h2>
          {error && <p className="text-danger">{error}</p>}

          <Form>
            <Form.Group controlId="recruiterSelect">
              <Form.Label>Select Recruiter</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setSelectedRecruiterId(parseInt(e.target.value))}
              >
                <option value={null}>Choose...</option>
                {recruiters.map((recruiter) => (
                  <option key={recruiter.id} value={recruiter.id}>
                    {recruiter.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="consultantName">
              {/* <Form.Label>Consultant Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter consultant name"
                value={newConsultantName}
                onChange={(e) => setNewConsultantName(e.target.value)}
              /> */}
              <Form.Label>Select Consultant</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setNewConsultantId(parseInt(e.target.value));
                  setTechnology(e.target.name);
                }}
              >
                <option value={null}>Choose...</option>
                {consultants.map((recruiter) => (
                  <option key={recruiter.id} name={recruiter.technology} value={recruiter.id}>
                    {recruiter.name} -  {recruiter.technology}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <br/>
            {/* <Button variant="dark" onClick={handleAddConsultant}>
              Add Consultant
            </Button> */}
            <Button variant="info" type="submit" disabled={spinner} onClick={handleAddConsultant}>
          {spinner ? (
            <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
            </>
          ) : (
            'Add Consultant'
          )}
        </Button>
          </Form>
        </Col>
        <Col md={4}>
        <div>
      <h2>Login Activity</h2>
      {loginActivity.length === 0 && <p>no results found.</p>}
     {loginActivity.length>0 && <Table striped bordered hover>
        <thead>
          <tr className="table-info">
            <th>ID</th>
            <th>Name</th>
            <th>Role</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {loginActivity.map((activity) => (
            <tr key={activity.id}>
              <td>{activity.id}</td>
              <td>{activity.name}</td>
              <td>{activity.role}</td>
              <td>{activity.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </Table>}
    </div>
        </Col>
      </Row>
    </Container>)}
    </div>
 
  );
}

export default AdminPortal;
