import React, { useState , useEffect} from 'react';
import axios from 'axios';
import { Alert, Spinner } from 'react-bootstrap';
import {BASE_URL} from '../constants';
const SubmissionForm = () => {
   

    const [consultantsData, setConsultantsData] = useState([]);
    const [error, setError] = useState(null);

    const recruiterId= sessionStorage.getItem('userId');
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        date: '',
        vendor: '',
        vendorEmail: '',
        vendorPhone: '',
        experience: '',
        workType: 'Remote',
        rate: '',
        clientLocation: '',
        role: '',
        clientName: '',
        jobdescription:'',
        comments: '',
        consultantid:'',
        consultantname:'',
        recruitid: sessionStorage.getItem('userId'),
        recruitername:sessionStorage.getItem('name'),
        company:sessionStorage.getItem('company'),
      });
      const [successMessage, setSuccessMessage] = useState('');
      const company = sessionStorage.getItem('company');
    useEffect(() => {
        
        const fetchData = async () => {
          try {
            const response = await fetch(BASE_URL+`/recAndAssignedCons/${recruiterId}/${company}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const result = await response.json();
          //  setData(result);
          console.log('recruiters data',result);
            setConsultantsData(result);
            setLoading(false);
          } catch (err) {
            setError(err);
            setLoading(false);
          }
        };
    
        fetchData();
  
      }, [recruiterId]);
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle submission and store the data
    console.log(formData); // For testing, you can see the form data in the console
    setLoading(true);
       
    setTimeout(async () => {
        let newFormData = consultantsData.find((r) => r.id === parseInt(formData.consultantid));
        formData.consultantname =newFormData?.name;

        formData.recruitid = sessionStorage.getItem('userId');

        formData.recruitername = sessionStorage.getItem('name');
        formData.company = sessionStorage.getItem('company');
      try{
        axios.post(BASE_URL+"/submission", formData).then((response) => {
          console.log(response.data);
          setSuccessMessage(response.data.message);
          setLoading(false);

        //  setSuccessMessage(response.data.message);
        //  setLoading(false);
        setFormData({
            date: '',
            vendor: '',
            vendorEmail: '',
            vendorPhone: '',
            experience: '',
            workType: 'Remote',
            rate: '',
            clientLocation: '',
            role: '',
            clientName: '',
            jobdescription:'',
            comments: '',
           consultantid:'', 
            recruitid: '',
            consultantname:'',
            recruitername:'',
            company:'',
          });
      
        });
      }catch(err){
        setLoading(false);
     
        setError(true);
      }finally {
        //setLoading(false); // Hide loader when data is received (whether success or error)
      }
    },500);
  };

  return (
    <div className="mt-4">
        {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <h2>Add Submission Details</h2>
      <form onSubmit={handleSubmit}>
      <div className="form-group">
         
                     <label for="consultantid">Select a Consultant:</label>
                <select value={formData.consultantid} onChange={handleChange} class="form-control" id="consultantid" name="consultantid">
                <option >
                     Select Consultant
                    </option>
                {consultantsData.map((consultant) => (
                    <option key={consultant.id} value={consultant.id}>
                      {consultant.name}
                    </option>
                  ))}
                </select>
        </div>
        <div className="form-group">
          <label htmlFor="date">Date of Submission</label>
          <input
            type="date"
            className="form-control"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="vendor">Name of Vendor</label>
          <input
            type="text"
            className="form-control"
            id="vendor"
            name="vendor"
            value={formData.vendor}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="vendoremail">Vendor Email</label>
          <input
            type="text"
            className="form-control"
            id="vendorEmail"
            name="vendorEmail"
            value={formData.vendorEmail}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="vendor">Vendor Phone</label>
          <input
            type="text"
            className="form-control"
            id="vendorPhone"
            name="vendorPhone"
            value={formData.vendorPhone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="experience">Total Years of Experience</label>
          <input
            type="number"
            className="form-control"
            id="experience"
            name="experience"
            value={formData.experience}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="workType">Work Type</label>
          <select
            className="form-control"
            id="workType"
            name="workType"
            value={formData.workType}
            onChange={handleChange}
            required
          >
            <option value="Remote">Remote</option>
            <option value="Onsite">Onsite</option>
            <option value="Hybrid">Hybrid</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="rate">Rate</label>
          <input
            type="text"
            className="form-control"
            id="rate"
            name="rate"
            value={formData.rate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="role">Role</label>
          <input
            type="text"
            className="form-control"
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          />
           <div class="form-group">
                <label htmlFor="jobdescription">Job Description:</label>
                <textarea name='jobdescription'  value={formData.jobdescription}  onChange={handleChange} class="form-control" id="jobdescription" rows="4" placeholder="Enter JD"></textarea>
            </div>
        </div>
        <div className="form-group">
          <label htmlFor="clientLocation">Client Location</label>
          <input
            type="text"
            className="form-control"
            id="clientLocation"
            name="clientLocation"
            value={formData.clientLocation}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientName">Client Name</label>
          <input
            type="text"
            className="form-control"
            id="clientName"
            name="clientName"
            value={formData.clientName}
            onChange={handleChange}
            required
          />
        </div>
        <div class="form-group">
                <label htmlFor="comments">Comments:</label>
                <textarea   value={formData.comments} onChange={handleChange} class="form-control" name="comments" id="comments" rows="4" placeholder="Enter Comments"></textarea>
            </div>
        <br />
        <button type="submit" className="btn btn-dark">
        {loading ? (
            <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
            </>
          ) : (
            ' Add Submission'
          )}
         
        </button>
        <br />
      </form>
    </div>
  );
};

export default SubmissionForm;
