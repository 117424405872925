import React , {useState, useEffect} from 'react';
import { BASE_URL } from '../constants';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner, Alert, Button} from 'react-bootstrap';
import { Navbar, Nav, NavDropdown , Table, Pagination , Tabs, Tab, Modal, Form  } from 'react-bootstrap';
import logo from '../reclogo.png';
import axios from 'axios';
import HotListForm from './HotListForm';
import MainNavbar from './MainNavBar';
function EmailHotlist() {

    const [userName, setUserName] = useState('');
    const company = sessionStorage.getItem('company');

    const [externalData, setExternalData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50; // Number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = externalData.slice(indexOfFirstItem, indexOfLastItem);

    const [tableData , setTableData] = useState([]);

    
    const [internalData, setInternalData] = useState([]);
    const [currentPage1, setCurrentPage1] = useState(1);
    const itemsPerPage1 = 50; // Number of items per page

    const indexOfLastItem1 = currentPage1 * itemsPerPage1;
    const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage1;
    const currentItems1 = internalData.slice(indexOfFirstItem1, indexOfLastItem1);
  
  // Simulate user authentication and fetching user data
  useEffect(() => {
    // Replace this with your authentication logic
    // For demonstration purposes, we'll use a setTimeout to simulate an API call.
    setTimeout(() => {
      const user = { name: 'TESTER' }; // Replace with actual user data
      setUserName(sessionStorage.getItem('username'));
   try{
    axios.get(BASE_URL+`/externalVendors/${company}`) // Replace with your API endpoint
    .then((response) => {
        setExternalData(response.data);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
   }catch(err){
    console.log(err);
   }
       // Fetch external data from your API or source
   


 // Fetch internal data from your API or source
  try{
    axios.get(BASE_URL+`/internalVendors/${company}`) // Replace with your API endpoint
    .then((response) => {
        setInternalData(response.data);
        const filteredData = response.data.filter((item) => item.hasOwnProperty('vendoremail'));
        const emailArray = filteredData.map((item) => item.vendoremail.trim());
   
        console.log(emailArray);
       setRecipients(emailArray);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
   
  }catch(err){
    console.log(err);
  }


 const handleView = async () => {

  try {
    const response = await fetch(`${BASE_URL}/viewHotlist/${company}`); // Update the URL to your API endpoint
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
   
    setTableData(data);
    setLoading(false);
  } catch (error) {
    console.error('Error fetching hotlist data:', error);
  }

};
handleView();

    }, 500); // Simulating a delay



  }, []);
  // const tableData = [
  //   { name: 'John Doe', age: 30, email: 'john@example.com' },
  //   { name: 'Jane Smith', age: 28, email: 'jane@example.com' },
  //   { name: 'Bob Johnson', age: 35, email: 'bob@example.com' },
  // ];
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [subject, setSubject] = useState('New Hotlist- Available consultants for c2c roles');
  const [message, setMessage] = useState('Hello,\n\nThis is an important announcement.');

  const [recipients, setRecipients] = useState([]);


  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [fromEmail , setFromEmail] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContinue = () => {
    // Do something with the form data
    console.log('Form Data:', formData);
  // setFromEmail(formData.email);
    // Close the modal
    createDynamicData();
    handleSendEmail(formData.email);
    handleClose();
  };
 // Calculate the index of the first and last item to display on the current page


 // Function to change the current page
 const handlePageChange = (pageNumber) => {
   setCurrentPage(pageNumber);
 };

 let tableRows =null;
 let emailSignature =null;
 let emailContentBody = null;
 let emailText = null;

 const createDynamicData = () => {
  // Generate the HTML content for the table dynamically
tableRows = tableData.map((row) => `
  <tr>
    <td style="border: 1px solid #ccc; padding: 8px;">${row.technology}</td>
    <td style="border: 1px solid #ccc; padding: 8px;">${row.experience}</td>
    <td style="border: 1px solid #ccc; padding: 8px;">${row.relocation}</td>
  </tr>
`).join('');

 emailContentBody = `
  <html>
    <body>
      <div style="text-align: center;">
        <h1>Consultants Hotlist</h1>
        <table style="border-collapse: collapse; width: 100%;">
          <thead>
            <tr>
              <th style="border: 1px solid #ccc; padding: 8px;">Technology</th>
              <th style="border: 1px solid #ccc; padding: 8px;">Experience</th>
              <th style="border: 1px solid #ccc; padding: 8px;">Relocation</th>
            </tr>
          </thead>
          <tbody>
   
            ${tableRows}
          </tbody>
        </table>
      </div>

    </body>
  </html>
`;
 emailSignature = `
<p>Please let us know if you are interested in any of these consultants or if you have specific requirements in mind. We are here to assist you in finding the right talent for your projects.</p>
  
<p>Thank you for considering our hot consultants list. We look forward to working with you.</p>

<p>Best regards,</p>
<p>${formData.name}</p>
<p>${formData.company}</p>
<p>Phone : ${formData.phone}</p>
<p>Email : ${formData.email}</p>`;
 emailText =`
<p>Hi,</p>
<p>We are excited to share our list of hot consultants who are actively seeking new opportunities. These talented professionals are available for immediate placement:</p>

<!-- Ta`;

 }

const poweredBy = `<p style="margin-top: 20px;">Powered by <strong>recmonitor</strong></p>
<img src="https://recmonitor.com/static/media/reclogo.cc526c2d814f1ee22c3d.png" alt="recmonitor Logo" style="width: 100px; height: auto;">`;


  const handleSendEmail = async (email) => {
    console.log(recipients);
    let emailContent ="";
    console.log(emailContentBody);
    //emailContent="";
    emailContent = emailText + emailContentBody + emailSignature + poweredBy ;
    try {
      const response = await axios.post(`${BASE_URL}/sendBulkEmail`, {
        email,
        recipients,
        subject,
        emailContent,
        company
      });

      if (response.status === 200) {
        alert('Bulk email sent successfully');
      } else {
        alert('Error sending bulk email');
      }
    } catch (error) {
      console.error(error);
      alert('Error sending bulk email');
    }
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    setLoading(true);
    setTimeout(async () => {
      try{
        axios.post(BASE_URL+"/uploadVendors", formData).then((response) => {
          console.log(response.data);
          setSuccessMessage(response.data.message);
          setLoading(false);
        });
      }catch(err){
        setLoading(false);
        setError(true);
      }finally {
        setLoading(false); // Hide loader when data is received (whether success or error)
      }
    },500);

    
  };
   // Dummy event handler for the button
   const handleButtonClick = () => {
    const filteredData = externalData.filter((item) => item.hasOwnProperty('email'));
    const emailArray = filteredData.map((item) => item.email.trim());
    setRecipients(emailArray);
    setShow(true);
  };

  return (
    <>
    <MainNavbar userName={userName}/>
     {/* <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#/Admin">  <img src={logo} alt='logo' width={80} height={80} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {userName && (
            <NavDropdown title={`Welcome, ${userName}`} id="basic-nav-dropdown">
              <NavDropdown.Item href="#/RecruiterStatusTrack">RecruiterStatusTrack</NavDropdown.Item>
              <NavDropdown.Item href="#/BulkMail">Send Hotlist</NavDropdown.Item>
              <NavDropdown.Item href="#/Applications">Applications</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/">Logout</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar> */}
 
    <div className="container">
    <div>
      {/* <Button variant="primary" onClick={handleShow}>
        Open Modal
      </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="company">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleContinue}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
      <div>
        <h2>Add Hot List </h2>
        <HotListForm/>
        <br />
    </div>
       
   
      <Tabs defaultActiveKey="external" id="vendor-tabs">
        <Tab eventKey="external" title="External Vendors">
        <br />
        <div className="row">
        <div className="col-md-6 offset-md-3">
          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <h2 className="text-center">Upload Vendor Data</h2>
        
          {/* <Button onClick={handleSendEmail} variant="primary" className="mb-3">
            Send Email
          </Button> */}
           <input type="file" onChange={handleFileChange} />
          <Button
            className="btn btn-dark mb-3"
            onClick={handleUpload}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              'Upload'
            )}
          </Button>
        </div>
      </div>
        <div className="d-flex align-items-center">
      
  <h4>Total Count: {externalData.length}</h4>
  <Button variant="success" className="ms-3" onClick={handleButtonClick}>
   Send Hotlist to External Vendors
  </Button>
</div>
<br />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: Math.ceil(externalData.length / itemsPerPage) }).map((_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(externalData.length / itemsPerPage)}
            />
          </Pagination>
        </Tab>
        <Tab eventKey="internal" title="Internal Vendors">
          {/* Internal vendors content */}
          <br />
        <div className="d-flex align-items-center">
      
  <h4>Total Count: {internalData.length}</h4>
  <Button variant="info" className="ms-3" onClick={handleShow}>
   Send Hotlist to Internal Vendors
  </Button>
</div>
<br />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {currentItems1.map((item, inxe) => (
                <tr key={item.index}>
                 <td>{item.index}</td>
                  <td>{item.vendorname}</td>
                  <td>{item.vendoremail}</td>
                  <td>{item.vendorphone}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage1 - 1)}
              disabled={currentPage1 === 1}
            />
            {Array.from({ length: Math.ceil(internalData.length / itemsPerPage1) }).map((_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage1}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage1 + 1)}
              disabled={currentPage1 === Math.ceil(internalData.length / itemsPerPage1)}
            />
          </Pagination>
        </Tab>
      </Tabs>
    </div>
    </>
  );
}



export default EmailHotlist;
