import React, { useState } from 'react';
import { Form, Button, Alert, Container, Row, Col, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { resolvePath, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../constants';
const PasswordReset = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    userId:'',
  });
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      userId: sessionStorage.getItem('userId'),
    });
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    console.log('form data', formData);
    formData.company =  sessionStorage.getItem('company');

    setTimeout(async () => {
    try {
      const response = await axios.post(BASE_URL+"/update-password", formData);
  
     console.log(response.data);

      setUserData(response.data);
     if(response.data.error){
      setError(response.data.error);

     }else{
      setSuccessMessage('Password reset successful.');
      (sessionStorage.getItem('role') === 'RECRUITER')?  navigate('/RecDashboard') : navigate('/ConsultantPortal');

     }
     
      //setError(null);
    } catch (err) {
   
      setError(err);
      setUserData(null);
    } finally {
      setLoading(false); // Hide loader when data is received (whether success or error)
    }
  }, 500); // Delay for 500 milliseconds before making the API call
  
  };


  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className="mb-4">Password Reset</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="oldPassword">
              <Form.Label>Old Password</Form.Label>
              <input
                type="password"
                name="oldPassword"
                className="form-control"
                value={formData.oldPassword}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <input
                type="password"
                name="newPassword"
                className="form-control"
                value={formData.newPassword}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <br />
            <Button variant="dark" type="submit" disabled={loading} onClick={handleSubmit}>
          {loading ? (
            <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
            </>
          ) : (
            'Reset Passwword'
          )}
        </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordReset;
