import React, { useState } from 'react';
import { Button, Form, Modal, Table, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../constants';

const HotListForm = () => {
  const [formData, setFormData] = useState({ technology: '', experience: '', relocation: '' , company: ''});
  const [updateFormData, setUpdateFormData] = useState({ technology: '', experience: '', relocation: '' });

  const [hotList, setHotList] = useState([]);
  const [showView, setShowView] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const company = sessionStorage.getItem('company');
  const [hotlistData, setHotlistData] = useState([]);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

 

const handleSave = async () => {
    setLoading(true);
    formData.company =company;
    try {
      // Make an API request to send the form data
      const response = await axios.post(`${BASE_URL}/hotlist`, formData);
      setResponse(response.data.message);
    } catch (err) {
      setError(err.response.data.error);
    }
  
    setLoading(false);
  };
  const handleUpdate = async () => {
    setLoading(true);
    const obj={};
    obj.id = id;
    obj.relocation = relocation;
    obj.technology= technology;
    obj.experience =experience;
    obj.company =company;
   
    try {
      // Make an API request to send the form data
      const response = await axios.post(`${BASE_URL}/hotlist`, obj);
      setResponse(response.data.message);
      handleView();
      setShowEdit(false);
    } catch (err) {
      setError(err.response.data.error);
    }
  
    setLoading(false);
  };


  const handleView = async () => {
  
        try {
          const response = await fetch(`${BASE_URL}/viewHotlist/${company}`); // Update the URL to your API endpoint
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setShowView(true);

          setHotlistData(data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching hotlist data:', error);
        }
      
  };

  const handleEdit = (item) => {
   setTechnology(item.technology);
   setRelocation(item.relocation);
   setExperience(item.experience);
   setId(item.id);
    setShowEdit(true);
  };

  const [technology, setTechnology] = useState(selectedItem ? selectedItem.technology : '');
  const [experience, setExperience] = useState(selectedItem ? selectedItem.experience : '');
  const [relocation, setRelocation] = useState(selectedItem ? selectedItem.relocation : '');
  const [id, setId] = useState(selectedItem ? selectedItem.id : '');

  const handleTechnologyChange = (e) => {
    setTechnology(e.target.value);
  };

  const handleExperienceChange = (e) => {
    setExperience(e.target.value);
  };

  const handleRelocationChange = (e) => {
    setRelocation(e.target.value);
  };

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Label>Technology</Form.Label>
          <Form.Control
            type="text"
            name="technology"
            value={formData.technology}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Total Exp</Form.Label>
          <Form.Control
            type="text"
            name="experience"
            value={formData.experience}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Relocation</Form.Label>
          <Form.Control
            type="text"
            name="relocation"
            value={formData.relocation}
            onChange={handleChange}
          />
        </Form.Group>
        <br />
        <div style={{display : 'flex', gap: '1rem'}}>
  <Button variant="primary" onClick={handleSave}>
  {loading ? (
            <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
            </>
          ) : (
            'Save'
          )}
  </Button>

  <Button variant="secondary" onClick={handleView}>
    ViewHotlist
  </Button>
</div>
{response && <p>Response: {response}</p>}
    {error && <p>Error: {error}</p>}
      
      </Form>

      {/* View Hot List Modal */}
      <Modal show={showView} onHide={() => setShowView(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Hot List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
              <th style={{'display': 'none'}}>Id</th>
                <th>Technology</th>
                <th>Experience</th>
                <th>Relocation</th>
              </tr>
            </thead>
            <tbody>
              {hotlistData.map((item, index) => (
                <tr key={index}>
                    <td style={{'display': 'none'}}>{item.id}</td>
                  <td>{item.technology}</td>
                  <td>{item.experience}</td>
                  <td>{item.relocation}</td>
                  <td>
                    <Button onClick={() => handleEdit(item)}>Edit</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* Edit Hot List Modal */}
      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Hot List Entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Technology</Form.Label>
            <Form.Control
              type="text"
              name="technology"
              value={technology}
              onChange={handleTechnologyChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Experience</Form.Label>
            <Form.Control
              type="text"
              name="experience"
              value={experience}
              onChange={handleExperienceChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Relocation</Form.Label>
            <Form.Control
              type="text"
              name="relocation"
              value={relocation}
              onChange={handleRelocationChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowEdit(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => handleUpdate()}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
};

export default HotListForm;
