// src/ConsultantList.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from 'react-bootstrap';
import {BASE_URL} from '../constants';
import axios from 'axios';
//   const consultantsData= [
//     { id: 1, name: 'shiva', email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey', technology:'java'},
//     { id: 2, name: 'esther', email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey', technology:'java'},
//     { id: 3, name: 'esha', email: 'test@gmail.com', phone: '559-917-6123',location: 'New jersey', technology:'java'},
//     // Add more recruiters here as needed
//   ];
const ConsultantList = () => {
    const [consultantsData, setConsultantsData] = useState([]);
     const [error, setError] = useState(null);
     const company = sessionStorage.getItem('company');
    const recruiterId= sessionStorage.getItem('userId');

    useEffect(() => {

  
        const fetchData = async () => {
          try {
            const url =BASE_URL+`/recAndAssignedCons/${recruiterId}/${company}`;
            console.log(url);
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const result = await response.json();
          //  setData(result);
          console.log('recruiters data',result);
            setConsultantsData(result);
            const idArray = result.map(item => item.id);
            sessionStorage.setItem('consultants' , idArray);

            
          //  setIsLoading(false);
          } catch (err) {
            setError(err);
           // setIsLoading(false);
          }
        };
    
        fetchData();
      }, [recruiterId]);
  return (
    <div>
      <h2 className="mt-4 mb-3">Consultants Assigned to You</h2>
      <div className="row">
        {consultantsData.length ===0 ? <p>No consultants assiged yet...</p>:consultantsData.map((consultant) => (
          <div className="col-md-6" key={consultant.id}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>{consultant.name}</Card.Title>
                <Card.Text>
                  <strong>Eamil:</strong> {consultant.email}
                </Card.Text>
                <Card.Text>
                  <strong>Phone:</strong> {consultant.phone}
                </Card.Text>
                <Card.Text>
                  <strong>Location:</strong> {consultant.location}
                </Card.Text>
                <Card.Text>
                  <strong>Technology:</strong> {consultant.technology}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConsultantList;
