import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Table,
  Alert,
  Modal
} from "react-bootstrap";
import axios from 'axios'; // Import axios for API calls
import { BASE_URL } from "../constants";
import "../index.css";
import MainNavbar from "./MainNavBar";
const modalStyles = {
  content: {
    width: "500px", // Set the desired width
    height: "500px", // Set the desired height
    margin: "auto", // Center the modal horizontally
  },
};

const Attendance = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [wfhStartDate, setWfhStartDate] = useState("");
  const [wfhEndDate, setWfhEndDate] = useState("");

  const [historyFromDate, setHistoryFromDate] = useState("");
  const [historyToDate, setHistoryToDate] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState('');

  const userName = sessionStorage.getItem("username");
  const employeeName = sessionStorage.getItem("name");
  const company = sessionStorage.getItem("company");
  const [recAttendance, setRecAttendance] = useState([]);
  const employeeId = sessionStorage.getItem("userId");
  const [selectedRecruiterId, setSelectedRecruiterId] = useState(null);
  const [selectedRecruiterName, setSelectedRecruiterName] = useState(null);
  const [recruiters, setRecruiters] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [attendanceDetails, setAttendanceDetails] = useState([{}]);
  const [selectedRecruiter, setSelectedRecruiter] = useState(null);

  // Simulate user authentication and fetching user data
  useEffect(() => {
    console.log('use effect is called');
    setTimeout(() => {
      const fetchData = () => {
        try {
          const response = axios.get(BASE_URL + `/users/${company}`);
          response.then((response) => {
            // console.log(response.data);
            const recs = response.data.filter(
              (item) => item.role === "RECRUITER"
            );

            const updatedRecs = [...recruiters, ...recs];
            // console.log('recs', updatedRecs);
            setRecruiters([...updatedRecs]);

            //  setLoading(false);
          });
        } catch (err) {
          setError(err);
        }
      };
   
      fetchData();
    }, 1000); // Simulating a delay
  }, []);
  const approved = true;
  const handleInsertData = async () => {
    const fromDate = formatDate(wfhStartDate);
    const toDate = formatDate(wfhEndDate);
    try {
      const response = await fetch(BASE_URL+'/api/workingFromHome', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          selectedRecruiterId,
          selectedRecruiterName,
          fromDate,
          toDate,
          approved,
          company
        }),
      });

      const data = await response.json();
      console.log(data); // Log the response from the server
      setSuccessMessage(data.message);
    } catch (error) {
      console.error('Error:', error);
      setError(error);
    }
  };
  const handleFilter = async () => {
    const apiUrl =
      BASE_URL +
      `/api/getAttendanceCount?employeeId=${employeeId}&fromDate=${formatDate(
        startDate
      )}&toDate=${formatDate(endDate)}&company=${company}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.success) {
        // Process the attendance data
        console.log("Attendance Data:", data.attendanceData);
        setRecAttendance(data.attendanceData);
      } else {
        console.error("Error retrieving attendance count:", data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const formatDate = (originalDate) => {
    const dateArray = originalDate.split("-");
    const formattedDate = `${parseInt(dateArray[1])}/${parseInt(
      dateArray[2]
    )}/${dateArray[0]}`;
    return formattedDate;
  };
  const handleRecruiterChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setSelectedRecruiterId(parseInt(e.target.value));
    setSelectedRecruiterName(selectedOption.getAttribute('name'));
  };
  const [workingFromHomeData, setWorkingFromHomeData] = useState([]);

  const getWFHHistory = async () => {
    try {
      const response = await fetch(BASE_URL+`/api/getWorkingFromHomeData?fromDate=${formatDate(historyFromDate)}&toDate=${formatDate(historyToDate)}&company=${company}`);
      const data = await response.json();
      setWorkingFromHomeData(data.workingFromHomeData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleRowClick = (recruiter) => {
    setSelectedRecruiter(recruiter);
    setShowModal(true);

    axios.get(BASE_URL+`/api/getAttendanceDetails?employee_id=${recruiter.EMPLOYEE_ID}&company=${company}&startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`)
    .then(response => {
      setAttendanceDetails(response.data.attendanceData);
    })
    .catch(error => {
      console.error('Error fetching attendance details:', error);
    });
  };

  const handleCloseModal = () => {
    setSelectedRecruiter(null);
    setShowModal(false);
  };

  return (
    <>
      <MainNavbar userName={userName} />
      <Container>
        <h2>Recruiter Attendance Tracker</h2>
        <Row className="mb-3 align-items-end">
          <Col md={2}>
            <Form.Group controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={1} className="text-md-left text-center mt-2 mt-md-0">
            <Button variant="dark" type="button" onClick={handleFilter}>
              Filter
            </Button>
          </Col>
        </Row>

        <div>
          {recAttendance.length === 0 && <p>no results found.</p>}
          {recAttendance.length > 0 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Recruiter Name</th>
                  <th>Attendance Count</th>
                </tr>
              </thead>
              <tbody>
                {recAttendance.map((recruiter) => (
                  <tr
                    key={recruiter.recruiter_id}
                    className={
                      recruiter.total_submissions === 0 ? "text-danger" : ""
                    }
                    onClick={() => handleRowClick(recruiter)}
                  >
                    <td>{recruiter.EMPLOYEE_NAME}</td>
                    <td>{recruiter.attendanceCount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Recruiter Attendance Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {attendanceDetails && (
            <Table striped bordered hover>
            <thead>
              <tr>
                <th>Recruiter Name</th>
                <th>Date</th>
                <th>Check In Time</th>
                <th>Check Out Time</th>
              </tr>
            </thead>
            <tbody>
              {attendanceDetails.map((recruiter) => (
                <tr
                  key={recruiter.recruiter_id}
                  className={
                    recruiter.total_submissions === 0 ? "text-danger" : ""
                  }
                  onClick={() => handleRowClick(recruiter)}
                >
                  <td>{recruiter.EMPLOYEE_NAME}</td>
                  <td>{recruiter.CHECKIN_DATE}</td>
                  <td>{recruiter.CHECKIN_TIME}</td>
                  <td>{recruiter.CHECKOUT_TIME}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
        <div>
          <h1>Work from home approval</h1>
          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <Form>
            <Row className="mb-3 align-items-end">
              <Col md={2}>
                <Form.Group controlId="recruiterSelect">
                  <Form.Label>Select Recruiter</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleRecruiterChange}

                  >
                    <option value={null}>Choose...</option>
                    {recruiters.map((recruiter) => (
                      <option key={recruiter.id} name={recruiter.name} value={recruiter.id}>
                        {recruiter.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group controlId="startDate">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={wfhStartDate}
                    onChange={(e) => setWfhStartDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group controlId="endDate">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={wfhEndDate}
                    onChange={(e) => setWfhEndDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={1} className="text-md-left text-center mt-2 mt-md-0">
                <Button variant="dark" type="button" onClick={handleInsertData}>
                  Approve
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div>
            <h1>Working from home History</h1>
            <Row className="mb-3 align-items-end">
          <Col md={2}>
            <Form.Group controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={historyFromDate}
                onChange={(e) => setHistoryFromDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={historyToDate}
                onChange={(e) => setHistoryToDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={1} className="text-md-left text-center mt-2 mt-md-0">
            <Button variant="dark" type="button" onClick={getWFHHistory}>
              Filter
            </Button>
          </Col>
        </Row>
        </div>
        <div>
          {workingFromHomeData.length === 0 && <p>no results found.</p>}
          {workingFromHomeData.length > 0 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                 
                  <th>Name</th>
                  <th>From Date</th>
                  <th>To Date</th>
                </tr>
              </thead>
              <tbody>
                {workingFromHomeData.map((recruiter) => (
                  <tr
                    key={recruiter.EMPLOYEE_ID}
                 >
                    <td>{recruiter.EMPLOYEE_NAME}</td>
                    <td>{recruiter.FROM_DATE}</td>
                    <td>{recruiter.TO_DATE}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </Container>
    </>
  );
};

export default Attendance;
