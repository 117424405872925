import React, { useState } from 'react';
import Modal from 'react-modal';

// Initialize the Modal
Modal.setAppElement('#root'); // Replace '#root' with your root element id

function EmailModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [emailText, setEmailText] = useState(''); // Initial email text

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const sendEmail = () => {
    // You can use a `mailto` link to open the user's email client with pre-configured text.
    // Note: This opens the user's default email client, and the user must manually send the email.
    window.location.href = `mailto:?subject=Reminder&body=${encodeURIComponent(emailText)}`;
    closeModal();
  };

  return (
    <div>
      <button onClick={openModal}>Compose Email</button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Email Modal"
      >
        <h2>Compose Email</h2>
        <textarea
          rows="5"
          cols="40"
          value={emailText}
          onChange={(e) => setEmailText(e.target.value)}
          placeholder="Enter your email text..."
        />
        <button onClick={sendEmail}>Send</button>
        <button onClick={closeModal}>Cancel</button>
      </Modal>
    </div>
  );
}

export default EmailModal;