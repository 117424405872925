import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from './reclogo.png';
import AdminPortal from './views/AdminPortal';
const Header = () => {
  const [userName, setUserName] = useState('');

  // Simulate user authentication and fetching user data
  useEffect(() => {
    // Replace this with your authentication logic
    // For demonstration purposes, we'll use a setTimeout to simulate an API call.
    setTimeout(() => {
      const user = { name: 'TESTER' }; // Replace with actual user data
      setUserName(sessionStorage.getItem('username'));
    }, 1000); // Simulating a delay
  }, []);

  return (
    <>
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#/Admin">  <img src={logo} alt='logo' width={80} height={80} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {userName && (
            <NavDropdown title={`Welcome, ${userName}`} id="basic-nav-dropdown">
              {/* Add user-related dropdown menu items here */}
              <NavDropdown.Item href="#/RecruiterStatusTrack">RecruiterStatusTrack</NavDropdown.Item>
              <NavDropdown.Item href="#/BulkMail">Send Hotlist</NavDropdown.Item>
              <NavDropdown.Item href="#/Applications">Applications</NavDropdown.Item>
              <NavDropdown.Item href="#/Attendance">Attendance</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/">Logout</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <br/>
    <AdminPortal/>
    </>
  );
};

export default Header;
