import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, Row, Col, Button, Table, Modal } from 'react-bootstrap';

import { BASE_URL } from '../constants';
import axios from 'axios';
import '../index.css';
import MainNavbar from './MainNavBar';
const modalStyles = {
    content: {
      width: '500px',    // Set the desired width
      height: '500px',   // Set the desired height
      margin: 'auto',    // Center the modal horizontally
    },
  };

  
const Reports = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [recruiterSummary, setRecruiterSummary] = useState([]);
  const [consultantSummary, setConsultantSummary] = useState([]);

  const [selectedRecruiter, setSelectedRecruiter] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConsultantSubmissionModal, setConsultantSubmissionModal] = useState(false);

  const [error, setError] = useState('');
  const [showJobDescModal , setShowJobDescModal]=useState(false);
const [selectedJD , setSelectedJD]= useState(null);
  const [userName, setUserName] = useState('');
  const [submissionData, setSubmissionData] = useState([]);
const [selectedRecruiterId , setSelectedRecruiterId] = useState('');
const [zerosubmission, setZeroSubmission] = useState([]);
const [zerosubRecruiters, setZeroSubmissionRecruiters] = useState([]);
const [zerosubConsultants, setZeroSubmissionConsultants] = useState([]);
const company = sessionStorage.getItem('company');

  // Simulate user authentication and fetching user data
  useEffect(() => {

    setTimeout(() => {
   
      setUserName(sessionStorage.getItem('username'));
     

    //   const options = {
    //     method: 'GET',
    //     url: 'https://fresh-linkedin-profile-data.p.rapidapi.com/search-jobs',
    //     params: {
    //       geo_code: '103644278',
    //       date_posted: 'any_time',
    //       function_id: 'it',
    //       industry_code: '4,5',
    //       sort_by: 'most_recent',
    //       start: '0'
    //     },
    //     headers: {
    //       'X-RapidAPI-Key': 'dd7734b6e8msh41b8a3d3f446f26p15da14jsn7fc753553b34',
    //       'X-RapidAPI-Host': 'fresh-linkedin-profile-data.p.rapidapi.com'
    //     }
    //   };
    //   const fetchData = async () => {
    //   try {
    //     const response = await axios.request(options);
    //     console.log(response.data);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
    // fetchData();

    }, 1000); // Simulating a delay
  }, []);

  const handleFilter = () => {
   
         try {
         
        const response =  axios.get(BASE_URL+`/recruitersReport?startDate=${startDate}&endDate=${endDate}&company=${company}`);
           response.then(response => {
            console.log(response.data);
            setRecruiterSummary(response.data);

           });
        } catch (err) {
          setError(err);
         
        }
        try {
         
          const response =  axios.get(BASE_URL+`/consultantsReport?startDate=${startDate}&endDate=${endDate}&company=${company}`);
             response.then(response => {
              console.log(response.data);
              setConsultantSummary([]);
              setConsultantSummary(response.data);
  
             });
          } catch (err) {
            setError(err);
           
          }

        

        // try{ 
        // const response =  axios.get(BASE_URL+`/zeroSubmissionsData?startDate=${startDate}&endDate=${endDate}&company=${company}`);
        //    response.then(response => {
        //     console.log(response.data);
        //     setZeroSubmission(response.data);

        //     const zeroRecs = response.data.filter(item => item.role === 'RECRUITER');
        //     const zeroConsultants = response.data.filter(item => (item.role === 'CONSULTANT' &&  item.submission_count ===0));
        //     setZeroSubmissionRecruiters(zeroRecs);
        //     setZeroSubmissionConsultants(zeroConsultants);

        //    });
        // } catch (err) {
        //   setError(err);
         
        // }
      


  };

  const handleRowClick = (recruiterid) => {

    try {
         setSelectedRecruiterId(recruiterid);
        const response =  axios.get(BASE_URL+`/recruitersDetailReport?recruiterId=${recruiterid}&startDate=${startDate}&endDate=${endDate}&company=${company}`);
           response.then(response => {
            setSubmissionData([]);
            setSelectedRecruiter(response.data);
            setShowModal(true);
           });
        } catch (err) {
          setError(err);
         
        }
  
  };

  const getConsultantsSubmissionDetails = async (consultantid) => {

    try {
      const response = await fetch(BASE_URL+`/getAllSubmissions?consultantid=${consultantid}&startdate=${startDate}&enddate=${endDate}&company=${company}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setConsultantSubmissionModal(true);
      setSubmissionData([]);
     setSubmissionData(result);
      console.log(result);
     // setIsLoading(false);
    } catch (err) {
     // setError(err);
     // setIsLoading(false);
    }
  };
  const showFullDetails = async (consultantid) => {

    setShowJobDescModal(false);
        try {
            const response = await fetch(BASE_URL+`/getSubmissionDetails?consultantid=${consultantid}&recruiterid=${selectedRecruiterId}&startdate=${startDate}&enddate=${endDate}&company=${company}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const result = await response.json();
           setSubmissionData(result);
            console.log(result);
           // setIsLoading(false);
          } catch (err) {
           // setError(err);
           // setIsLoading(false);
          }
  
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseSubmissionsModal = () => {
    setConsultantSubmissionModal(false);
  };
//   const getConsultantCounts = () => {
//     const consultantCounts = {};
//     consultantData.forEach((consultant) => {
//       if (selectedRecruiter && consultant.recruiterId === selectedRecruiter.id) {
//         consultantCounts[consultant.name] =
//           consultantCounts[consultant.name] + 1 || 1;
//       }
//     });
//     return consultantCounts;
//   };

  const openModal = (jd) => {
    setSelectedJD(jd);
    console.log('model is open', showJobDescModal);
    setShowJobDescModal(true);
  };

  const closeModal = () => {
    setShowJobDescModal(false);
  };

  return (
    <>

    <MainNavbar userName={userName}/>

       {/* <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#/Admin">  <img src={logo} alt='logo' width={80} height={80} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {userName && (
            <NavDropdown title={`Welcome, ${userName}`} id="basic-nav-dropdown">
              <NavDropdown.Item href="#/RecruiterStatusTrack">RecruiterStatusTrack</NavDropdown.Item>
              <NavDropdown.Item href="#/BulkMail">Send Hotlist</NavDropdown.Item>
              <NavDropdown.Item href="#/Applications">Applications</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/">Logout</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar> */}
    <Container>
      <h2>Recruiter Submission Tracker</h2>
      <Row className="mb-3">
        <Col md={4}>
          <Form.Group controlId="startDate">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="endDate">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Button variant="dark" type="button" onClick={handleFilter}>
            Filter
          </Button>
        </Col>
      </Row>
      <div>
        <h3>Recruiter Submission Summary</h3>
        <h3>Total Recruiters : {recruiterSummary.length} Total Submissions: {recruiterSummary.reduce((acc, recruiter) => acc + recruiter.total_submissions, 0)}</h3>
       {recruiterSummary.length===0 && <p>no results found.</p>}
       {recruiterSummary.length >0 && <Table striped bordered hover>
          <thead>
            <tr>
              <th>Recruiter Name</th>
              <th>Submission Count</th>
            </tr>
          </thead>
          <tbody>
            {recruiterSummary.map((recruiter) => (
              <tr
                key={recruiter.recruiter_id}
                className={recruiter.total_submissions === 0 ? 'text-danger' : ''}
                onClick={() => handleRowClick(recruiter.recruiter_id)}
              >
                <td>{recruiter.recruiter_name}</td>
                <td>{recruiter.total_submissions}</td>
              </tr>
            ))}
          </tbody>
        </Table>}
       
        {/* <div className="container">
  <div className="row">
    <div className="col">
     <h4>Impacted Consultants</h4>
    {zerosubConsultants.length === 0 && <p>no results found.</p>}
      {zerosubConsultants.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Consultant Name</th>
              <th>Technology</th>
              <th>Submission</th>
            </tr>
          </thead>
          <tbody>
            {zerosubConsultants.map((submission) => (
              <tr key={submission.id}>
                <td>{submission.name}</td>
                <td>{submission.technology}</td>
                <td>{submission.submission_count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
    <div className="col">
    <h4>Poor performers</h4>
    {zerosubRecruiters.length === 0 && <p>no results found.</p>}
      {zerosubRecruiters.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Recruiter Name</th>
              <th>Submission</th>
            </tr>
          </thead>
          <tbody>
            {zerosubRecruiters.map((submission) => (
              <tr key={submission.id}>
                <td>{submission.name}</td>
                <td>{submission.submission_count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  </div>
</div> */}

      </div>

      <div>
        <h3>Consultant Submission Summary</h3>
        <h3>Total Submissions: {consultantSummary.reduce((acc, recruiter) => acc + recruiter.total_submissions, 0)}</h3>

       {consultantSummary.length===0 && <p>no results found.</p>}
       {consultantSummary.length >0 && <Table striped bordered hover>
          <thead>
            <tr>
              <th>Consultant Name</th>
              <th>Submission Count</th>
            </tr>
          </thead>
          <tbody>
            {consultantSummary.map((consultant) => (
              <tr
                key={consultant.consultant_id}
                className={consultant.total_submissions === 0 ? 'text-danger' : ''}
                onClick={() => getConsultantsSubmissionDetails(consultant.consultant_id)}
              >
                <td>{consultant.consultant_name} - {consultant.technology}</td>
                <td>{consultant.total_submissions}</td>
              </tr>
            ))}
          </tbody>
        </Table>}
        </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedRecruiter && selectedRecruiter.recruiterName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Individual Consultant Submission Counts</h4>
          <div style={{'overflow-x': 'auto'}}>
        {  <Table   striped bordered hover>
            <thead>
              <tr>
                <th>Consultant Name</th>
                <th>Submission Count</th>
              </tr>
            </thead>
            <tbody>
            
              {selectedRecruiter.length>0 && selectedRecruiter.map((recruiter) => (
            <tr key={recruiter.consultant_name} 
            onClick={() => showFullDetails(recruiter.consultant_id)}
            >
              <td>{recruiter.consultant_name}</td>
              <td>{recruiter.total_submissions}</td>
              
            </tr>
          ))}
            </tbody>
          </Table> }
          {submissionData.length> 0 && <Table  striped bordered hover  >
          <thead>
            <tr>
              <th>Consultant Name</th>
              <th>Date</th>
              <th>Vendor Name</th>
              <th>Vendor Email</th>
              <th>Vendor Phone</th>
              <th>Experience (Years)</th>
              <th>Work Type</th>
              <th>Rate</th>
              <th>Client Name</th>
              <th>Client Location</th>
              <th>Role</th>
              <th>Job Desc</th>
            </tr>
          </thead>
          <tbody>
            {submissionData.map((submission) => (
              <tr key={submission.id}>
                <td>{submission.consultantname}</td>
                <td>{submission.dateofsubmission}</td>
                <td>{submission.vendorname}</td>
                <td>{submission.vendoremail}</td>
                <td>{submission.vendorphone}</td>
                <td>{submission.totalexperience}</td>
                <td>{submission.worktype}</td>
                <td>{submission.rate}</td>
                <td>{submission.clientname}</td>
                <td>{submission.clientlocation}</td>
                <td>{submission.role}</td>
                <td>
                <a href="#" onClick={(e) => { e.preventDefault(); openModal(submission.jobdescription); }}>
                  View Job Description
                </a>
                <Modal style={modalStyles} show={showJobDescModal} onRequestClose={() => closeModal()}>
                  <h2>Job Description</h2>
                  <p>{selectedJD}</p>
        <Button
            variant="dark"
            type="button"
            onClick={() => closeModal()}
          >Close</Button>
                  
                </Modal>
              </td>
              </tr>
            ))}
          </tbody>
        </Table>}
       
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConsultantSubmissionModal} onHide={handleCloseSubmissionsModal}>
        <Modal.Header closeButton>
          <Modal.Title>All submissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>All Submissions</h4>
          <div style={{'overflow-x': 'auto'}}>
      
          {submissionData.length> 0 && <Table  striped bordered hover  >
          <thead>
            <tr>
              <th>Consultant Name</th>
              <th>Date</th>
              <th>Vendor Name</th>
              <th>Vendor Email</th>
              <th>Vendor Phone</th>
              <th>Experience (Years)</th>
              <th>Work Type</th>
              <th>Rate</th>
              <th>Client Name</th>
              <th>Client Location</th>
              <th>Role</th>
              <th>Job Desc</th>
            </tr>
          </thead>
          <tbody>
            {submissionData.map((submission) => (
              <tr key={submission.id}>
                <td>{submission.consultantname}</td>
                <td>{submission.dateofsubmission}</td>
                <td>{submission.vendorname}</td>
                <td>{submission.vendoremail}</td>
                <td>{submission.vendorphone}</td>
                <td>{submission.totalexperience}</td>
                <td>{submission.worktype}</td>
                <td>{submission.rate}</td>
                <td>{submission.clientname}</td>
                <td>{submission.clientlocation}</td>
                <td>{submission.role}</td>
                <td>
                <a href="#" onClick={(e) => { e.preventDefault(); openModal(submission.jobdescription); }}>
                  View Job Description
                </a>
                <Modal style={modalStyles} show={showJobDescModal} onRequestClose={() => closeModal()}>
                  <h2>Job Description</h2>
                  <p>{selectedJD}</p>
        <Button
            variant="dark"
            type="button"
            onClick={() => closeModal()}
          >Close</Button>
                  
                </Modal>
              </td>
              </tr>
            ))}
          </tbody>
        </Table>}
       
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSubmissionsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container></>
  );
};

export default Reports;
