// src/App.js
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ConsultantList from "./ConsultantList";
import SubmissionDetails from "./SubmissionDetails";
import SubmissionForm from "./SubmissionForm";
// import SubmissionCharts from './SubmissionCharts';
// import { staticSubmissionData } from './data';
import axios from "axios";
import {
  Navbar,
  Alert,
  Nav,
  NavDropdown,
  Table,
  Button,
} from "react-bootstrap";
import logo from "../reclogo.png";
import { BASE_URL } from "../constants";
import Modal from "react-modal";

function RecruiterDashboard() {
  const [userName, setUserName] = useState("");
  const [interviews, setInterviews] = useState([]);
  const recruiterId = sessionStorage.getItem("userId");
  const [openJdModal, setOpenJdModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const employeeId = sessionStorage.getItem("userId");
  const employeeName = sessionStorage.getItem("name");
  const company = sessionStorage.getItem("company");
  const [wfhApproved, setWfhApproved] =useState(false);
  const [selectedInterviewJD, setSelectedInterviewJD] = useState(null);
  const modalStyles = {
    content: {
      width: "500px", // Set the desired width
      height: "600px", // Set the desired height
      margin: "auto", // Center the modal horizontally
    },
  };

  const openDetailsModal = (jd) => {
    setSelectedDetails(jd);
    setDetailsModal(true);
  };
  const closeDetailsModal = () => {
    setDetailsModal(false);
  };
  const [checkedInTime, setCheckedInTime] = useState(false);
  const [checkedOutTime, setCheckedOutTime] = useState(false);

  const fetchAttendanceData = async () => {
    try {
      // Make a GET request to the API endpoint
      const response = await fetch(BASE_URL + `/api/attendance/${employeeId}/${company}`);
      if (!response.ok) {
        throw new Error("Failed to fetch attendance data");
      }

      // Parse the JSON response
      const data = await response.json();
      // console.log(data[0].IS_CHECKED_IN);
      if (data.length >0 && data[0].IS_CHECKED_IN) {
        setIsCheckInDone(true);
        if(data[0].IS_CHECKED_OUT){
          setIsCheckOutDone(true);
          const chkOutTime = data[0].CHECKOUT_DATE + data[0].CHECKOUT_TIME;
          setCheckedOutTime(chkOutTime);
  
        }
        const dtTime = data[0].CHECKIN_DATE + data[0].CHECKIN_TIME;
        setCheckedInTime(dtTime);

       
      }

      // setAttendanceData(data);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };
  useEffect(() => {
    // Replace this with your authentication logic
    // For demonstration purposes, we'll use a setTimeout to simulate an API call.
    const fetchIPAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();

        if (response.ok) {
          setIpAddress(data.ip);
        } else {
          console.error("Failed to fetch IP address:", data);
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchAttendanceData();
    fetchIPAddress();
    const fetchInterviews = async () => {
      const consultantidsString = sessionStorage.getItem("consultants");
      const consultantidsArray = consultantidsString.split(",").map(Number);

      axios
        .post(BASE_URL + `/interviews-data`, {
          recruitid: recruiterId,
          consultantids: consultantidsArray,
        })
        .then((response) => {
          // setInterviews(response.data);
          const deduplicatedData = removeDuplicates(response.data);
          console.log("avoid duplicates", deduplicatedData);
          setInterviews(deduplicatedData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    const removeDuplicates = (arr) => {
      return arr.filter((value, index, self) => {
        const jsonValue = JSON.stringify(value);
        return (
          index === self.findIndex((obj) => JSON.stringify(obj) === jsonValue)
        );
      });
    };
    const fetchWFHData = async () => {
      try {
        const response = await fetch(BASE_URL+`/api/getEmployeeWorkingFromHomeData?employeeId=${employeeId}&company=${company}`);
        const data = await response.json();

        if (data.success) {
         // setWorkingFromHomeData(data.workingFromHomeData);
         console.log(data.workingFromHomeData[0].APPROVED);
         if(data?.workingFromHomeData.length>0 && data?.workingFromHomeData[0]?.APPROVED){
          setWfhApproved(true);
         }
        } else {
          console.error('Error fetching data:', data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchWFHData();
    setTimeout(async () => {
      const user = { name: "TESTER" }; // Replace with actual user data
      setUserName(sessionStorage.getItem("name"));

      fetchInterviews();
    }, 500); // Simulating a delay
  }, []);

  const openJDModal = (jd) => {
    setSelectedInterviewJD(jd);
    setOpenJdModal(true);
  };
  const closeJDModal = () => {
    setOpenJdModal(false);
  };
  const [isCheckInDone, setIsCheckInDone] = useState(false);
  const [isCheckOutDone, setIsCheckOutDone] = useState(false);

  const handleCheckout = () => {
    fetch(BASE_URL+'/api/checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ employeeId, ipAddress , isCheckInDone, company, wfhApproved }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data); // Log the API response
      
        if(data.success){
          setSuccessMessage(data.message);
          setIsCheckOutDone(true);
        }else{
          if(isCheckInDone){
            setError(data.message);
          }else{
            setError('You need to checkin before checkout');

          }
         
        }
      })
      .catch(error => {
        console.error('Error checking out:', error);
        setError(error);
        // Handle the error, e.g., show an error message to the user
      });
  };
  const handleCheckin = async () => {
    try {
      const response = await fetch(BASE_URL + "/api/checkin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ employeeId, employeeName, ipAddress, company , wfhApproved }),
      });

      const data = await response.json();

      if (response.ok) {
        setIsCheckInDone(true);
        setSuccessMessage(data.message);
        console.log("Check-in successful:", data.message);

        // Handle success as needed
      } else {
        setError(data.message);
        console.error("Check-in failed:", data.message);
        // Handle failure as needed
      }
    } catch (error) {
      console.error("Error during check-in:", error);
      // Handle error as needed
    }
  };

  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/ConsultantPortal">
          {" "}
          <img src={logo} alt="logo" width={80} height={80} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {userName && (
              <NavDropdown
                title={`Welcome, ${userName}`}
                id="basic-nav-dropdown"
              >
                {/* Add user-related dropdown menu items here */}
                {/* <NavDropdown.Item href="/Reports">Reports</NavDropdown.Item> */}
                {/* <NavDropdown.Divider /> */}
                <NavDropdown.Item href="#/PasswordReset">
                  Reset Password
                </NavDropdown.Item>
                <NavDropdown.Item href="/">Logout</NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="container mt-5">
        <div id="scroll-container">
          <div id="scroll-text">
            Placing 24 candidates in 2024 earns you a car gift **** Placing 15
            candidates in 2024 qualifies you for a bike gift.
          </div>
        </div>
        <h1>Recruiter Portal</h1>
        {error && <Alert variant="danger">{error}</Alert>}
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        <div className="container mt-4">
          <div className="btn-group">
          {wfhApproved && <button
              className={`btn btn-primary mr-2 ${
                isCheckInDone ? "disabled" : ""
              }`}
              onClick={() => handleCheckin("Check-In")}
            >
              WFH Check-In
            </button>}
           {!wfhApproved && <button
              className={`btn btn-primary mr-2 ${
                isCheckInDone ? "disabled" : ""
              }`}
              onClick={() => handleCheckin("Check-In")}
            >
              Check-In
            </button>}
            {/* <button
              className="btn btn-primary mr-2"
              onClick={() => handleButtonClick("Dinner Break Start")}
            >
              Dinner Break Start
            </button>
            <button
              className="btn btn-primary mr-2"
              onClick={() => handleButtonClick("Dinner Break End")}
            >
              Dinner Break End
            </button> */}
             {wfhApproved && <button
             className={`btn btn-primary mr-2 ${
              isCheckOutDone ? "disabled" : ""
            }`}
              onClick={() => handleCheckout("Check-Out")}
            >
              WFH Check-Out
            </button>}
            {!wfhApproved && <button
             className={`btn btn-primary mr-2 ${
              isCheckOutDone ? "disabled" : ""
            }`}
              onClick={() => handleCheckout("Check-Out")}
            >
              Check-Out
            </button>}
          </div>
          {checkedInTime && <p>Your last checkin : {checkedInTime}</p>}
          {checkedOutTime && <p>Your last checkout : {checkedOutTime}</p>}
        </div>
        <div className="row">
          <div className="col-md-4">
            <ConsultantList />
            {/* <SubmissionCharts /> */}
          </div>

          <div className="col-md-8">
            <SubmissionForm />
          </div>
          <div className="container mt-4">
            <h2>Interviews History</h2>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>date</th>
                  <th>date</th>
                  <th>time</th>
                  <th>round</th>
                  <th>mode</th>
                  <th>vendor</th>
                  <th>vendoremail</th>
                  <th>jd</th>
                  <th>details</th>
                  <th>clientname</th>
                  <th>clientlocation</th>
                  <th>recruiter</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                {interviews.map((interview) => (
                  <tr key={interview.id}>
                    <td>{interview.name}</td>
                    <td>{interview.date}</td>
                    <td>{interview.time}</td>
                    <td
                      className={
                        interview.status === "selected"
                          ? "green-row"
                          : interview.status === "rejected"
                          ? "red-row"
                          : ""
                      }
                    >
                      {interview.round}
                    </td>
                    <td>{interview.mode}</td>
                    <td>{interview.vendor}</td>
                    <td>{interview.vendoremail}</td>

                    <td>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          openJDModal(interview.jd);
                        }}
                      >
                        View Job Description
                      </a>
                      <Modal
                        style={modalStyles}
                        isOpen={openJdModal}
                        onRequestClose={() => closeJDModal()}
                      >
                        <h2>Job Description</h2>
                        <p>{interview.jd}</p>
                        <Button
                          variant="dark"
                          type="button"
                          onClick={() => closeJDModal()}
                        >
                          Close
                        </Button>
                      </Modal>
                    </td>
                    <td>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          openDetailsModal(interview.details);
                        }}
                      >
                        Details
                      </a>
                      <Modal
                        style={modalStyles}
                        isOpen={detailsModal}
                        onRequestClose={() => closeDetailsModal()}
                      >
                        <h2>Interview Details</h2>
                        <p>{selectedDetails}</p>
                        <Button
                          variant="dark"
                          type="button"
                          onClick={() => closeDetailsModal()}
                        >
                          Close
                        </Button>
                      </Modal>
                    </td>
                    <td>{interview.clientname}</td>
                    <td>{interview.clientlocation}</td>
                    <td>{interview.recruitername}</td>
                    <td
                      className={
                        interview.status === "selected"
                          ? "green-row"
                          : interview.status === "rejected"
                          ? "red-row"
                          : ""
                      }
                    >
                      {interview.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <SubmissionDetails />
        </div>
      </div>
    </div>
  );
}

export default RecruiterDashboard;
