import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import DateFilter from './DateFilter';
import { Navbar, Nav, NavDropdown , Table , Button} from 'react-bootstrap';
import logo from '../reclogo.png';
import axios from 'axios';
import { BASE_URL } from '../constants';
const ConsultantPortal = () => {
    const [userName, setUserName] = useState('');
    const [recruiterData, setRecruiterData] = useState([]);
    const [interviews, setInterviews] = useState([]);

    // const recruiterData = [
    //     { id: 1, name: 'Recruiter 1' , technology: 'java'},
    //     { id: 1, name: 'Recruiter 1' , technology: '.net'},
    //     { id: 2, name: 'Recruiter 2' ,technology: 'java'},
    //     { id: 3, name: 'Recruiter 3' ,technology: 'java'},
    //   ];
    const modalStyles = {
        content: {
          width: '500px',    // Set the desired width
          height: '600px',   // Set the desired height
          margin: 'auto',    // Center the modal horizontally
        },
      };
    
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] =useState(null);
  const name = sessionStorage.getItem('name');
  const company = sessionStorage.getItem('company');
  const recruiterid = sessionStorage.getItem('userId');

  useEffect(() => {
    // Replace this with your authentication logic
    // For demonstration purposes, we'll use a setTimeout to simulate an API call.
    setTimeout(() => {
     // const user = { name: 'TESTER' }; // Replace with actual user data
      setUserName(sessionStorage.getItem('username'));
        
     
        const fetchRecruiters = async () => {
         // setIsLoading(true); // Set loading state to true
        
          try {
            // Make the GET request to the API endpoint
            const response = await axios.get(BASE_URL+`/recruiters/${name}/${company}`);
    
            // Set the retrieved data in the state
            setRecruiterData(response.data);
            //setIsLoading(false); // Set loading state to false
          } catch (error) {
            setError(error); // Set the error state
            //setIsLoading(false); // Set loading state to false
          }

    
    }


    const logActivity = () =>{
     
        // Login Activity
     const role = sessionStorage.getItem('role');
     const name = sessionStorage.getItem('name');
     const company = sessionStorage.getItem('company');
    
      fetch(BASE_URL+`/login-activity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({recruiterid, name, role , company}),
      })
      .then(response => response.json())
      .then(data => {
        this.setState({ message: data.message });
      })
      .catch(error => {
        console.error(error);
      });
    }
                  
    const fetchInterviews = async () => {
      // setIsLoading(true); // Set loading state to true
     
       try {
        axios
        .get(BASE_URL+`/interviews/${recruiterid}`)
        .then((response) => {
          setInterviews(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data: ' + error);
        });
         //setIsLoading(false); // Set loading state to false
       } catch (error) {
         setError(error); // Set the error state
         //setIsLoading(false); // Set loading state to false
       }


}

fetchInterviews();
    logActivity();
    fetchRecruiters();



    }, 1000); // Simulating a delay
  }, [name, recruiterid]);


  const handleFilter = (startdate, enddate) => {


    const consultantname = sessionStorage.getItem('name');
        const fetchData = async () => {
           // setIsLoading(true);
      
            try {
              // Construct the URL with query parameters
              const url = BASE_URL+`/submissions/${consultantname}?startdate=${startdate}&enddate=${enddate}&company=${company}`;
              
              // Make the GET request to the API
              const response = await fetch(url);
      
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
      
              // Parse the response data
              const data = await response.json();
      
              // Update the submissions state with the data
              setFilteredData(data);
              //setIsLoading(false);
            } catch (error) {
              setError(error);
             // setIsLoading(false);
            }
          };
      
          fetchData();

        };
        const [open , setOpen]=useState(false);
        const [selectedJD , setSelectedJD]= useState(null);
        const [selectedInterviewJD , setSelectedInterviewJD]= useState(null);
        const [selectedDetails , setSelectedDetails]= useState(null);


        const [openJdModal , setOpenJdModal]=useState(false);
        const [detailsModal , setDetailsModal]=useState(false);


        const openModal = (jd) => {
            setSelectedJD(jd);
            setOpen(true);
          };
        
          const closeModal = () => {
            setOpen(false);
          };
          const openJDModal = (jd) => {
            setSelectedInterviewJD(jd);
            setOpenJdModal(true);
          };
          const closeJDModal = () => {
            setOpenJdModal(false);
          };

          const openDetailsModal = (jd) => {
            setSelectedDetails(jd);
            setDetailsModal(true);
          };
          const closeDetailsModal = () => {
            setDetailsModal(false);
          };

  return (
    <div>
      <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/ConsultantPortal">  <img src={logo} alt='logo' width={80} height={80} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {userName && (
            <NavDropdown title={`Welcome, ${userName}`} id="basic-nav-dropdown">
              {/* Add user-related dropdown menu items here */}
              {/* <NavDropdown.Item href="/Reports">Reports</NavDropdown.Item> */}
              {/* <NavDropdown.Divider /> */}
              <NavDropdown.Item href="#/PasswordReset">Reset Password</NavDropdown.Item>

              <NavDropdown.Item href="/">Logout</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  
      <div className="container mt-4">
      <h2>Recruiters Working on My Profile</h2>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Technology</th>
            <th>Recruiter Email</th>
            <th>Recruiter Phone</th>
          </tr>
        </thead>
        <tbody>
          {recruiterData.map((recruiter) => (
            <tr key={recruiter.id}>
              <td>{recruiter.recruiter_name}</td>
              <td>{recruiter.recruiter_technology}</td>
              <td>{recruiter.recruiter_email}</td>
              <td>{recruiter.recruiter_phone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="container mt-4">
      <h2>Interviews History</h2>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>date</th>
            <th>time</th>
            <th>round</th>
            <th>mode</th>
            <th>vendor</th>
            <th>vendoremail</th>
            <th>jd</th>
            <th>details</th>
            <th>clientname</th>
            <th>clientlocation</th>
            <th>recruiter</th>
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          {interviews.map((interview) => (
            <tr key={interview.id} 
            >
              <td>{interview.date}</td>
              <td>{interview.time}</td>
              <td className={interview.status === 'selected' ? 'green-row' : interview.status === 'rejected' ? 'red-row' : ''}>{interview.round}</td>
              <td>{interview.mode}</td>
              <td>{interview.vendor}</td>
              <td>{interview.vendoremail}</td>
            
              <td>
                <a href="#" onClick={(e) => { e.preventDefault(); openJDModal(interview.jd); }}>
                  View Job Description
                </a>
                <Modal style={modalStyles} isOpen={openJdModal} onRequestClose={() => closeJDModal()}>
                  <h2>Job Description</h2>
                  <p>{interview.jd}</p>
        <Button
            variant="dark"
            type="button"
            onClick={() => closeJDModal()}
          >Close</Button>
                  
                </Modal>
              </td>
              <td>
                <a href="#" onClick={(e) => { e.preventDefault(); openDetailsModal(interview.details); }}>
                  Details
                </a>
                <Modal style={modalStyles} isOpen={detailsModal} onRequestClose={() => closeDetailsModal()}>
                  <h2>Interview Details</h2>
                  <p>{selectedDetails}</p>
        <Button
            variant="dark"
            type="button"
            onClick={() => closeDetailsModal()}
          >Close</Button>
                  
                </Modal>
              </td>
              <td>{interview.clientname}</td>
              <td>{interview.clientlocation}</td>
              <td>{interview.recruitername}</td>
              <td className={interview.status === 'selected' ? 'green-row' : interview.status === 'rejected' ? 'red-row' : ''}>{interview.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      <div>
        <br />
        <br />
      <DateFilter onSubmit={handleFilter} />
      {filteredData.length === 0 && <div className="container mt-4"><h4>no results found.</h4></div>}
      {filteredData.length >0 && <div className="container mt-4"><h3>Filtered Submission Data</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Consultant Name</th>
              <th>Recruiter Name</th>
              <th>Date</th>
              <th>Vendor</th>
              <th>Vendor Email</th>
              <th>Vendo Phone</th>          
              <th>Experience (Years)</th>
              <th>Work Type</th>
              <th>Rate</th>
              <th>Client Name</th>
              <th>Job Desc</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((submission) => (
              <tr key={submission.id}>
                <td>{submission.consultantname}</td>
                <td>{submission.recruitername}</td>
                <td>{submission.dateofsubmission}</td>
                <td>{submission.vendorname}</td>
                <td>{submission.vendoremail}</td>
                <td>{submission.vendorphone}</td>
                <td>{submission.totalexperience}</td>
                <td>{submission.worktype}</td>
                <td>{submission.rate}</td>
                <td>{submission.clientname}</td>
                <td>
                <a href="#" onClick={(e) => { e.preventDefault(); openModal(submission.jobdescription); }}>
                  View Job Description
                </a>
                <Modal style={modalStyles} isOpen={open} onRequestClose={() => closeModal()}>
                  <h2>Job Description</h2>
                  <p>{selectedJD}</p>
        <Button
            variant="dark"
            type="button"
            onClick={() => closeModal()}
          >Close</Button>
                  
                </Modal>
              </td>
              
              </tr>
            ))}
          </tbody>
        </Table></div>}
      </div>
    </div>
  );
};

export default ConsultantPortal;
