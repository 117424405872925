import React, { StrictMode } from 'react';
// import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// import ReactDOM from 'react-dom';
// import { BrowserRouter, Routes,  Route} from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import Jobs from './views/Jobs';
import RecruiterDashboard from './views/RecruiterDashboard';
import ConsultantPortal from './views/ConsultantPortal';
import Reports from './views/Reports';
import PasswordReset from './views/PasswordReset';
import PageNotFound from './views/PageNotFound';
import { HashRouter as Router,Routes, Route } from 'react-router-dom';
import EmailHotlist from './views/EmailHotlist';
import ApplicationsList from './views/ApplicationsList';
import Attendance from './views/Attendance';
const rootElement = 
document.getElementById('root');
const root = 
createRoot(rootElement);
root.render(
  <StrictMode>
  <Router>
<Routes>
        <Route path="*" element={<PageNotFound />} /> 
        <Route path="/" element={<Login />} />
        <Route path="/Admin" element={<Home />} />
        <Route path="/RecDashboard" element={<RecruiterDashboard />} />
        <Route path="/ConsultantPortal" element={<ConsultantPortal />} />
        <Route path="/RecruiterStatusTrack" element={<Reports />} />
        <Route path="/PasswordReset" element={<PasswordReset />} />
        <Route path="/Bulkmail" element={<EmailHotlist />} />
        <Route path="/careers" element={<Jobs />} />
        <Route path="/Applications" element={<ApplicationsList />} />
        <Route path="/Attendance" element={<Attendance />} />

        </Routes>
    </Router>
    </StrictMode>,
);