import React from 'react';
import JobList from './JobList';
import ResumeForm from './ResumeForm';

const Jobs = () => {
    return (
        <div className="App">
          <header>
         
          </header>
          <main>
            <JobList />
            {/* <ResumeForm /> */}
          </main>
        </div>
      );

}


export default Jobs;