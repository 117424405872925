import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Stack , Alert, Spinner} from 'react-bootstrap';
import logo from './reclogo.png';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {BASE_URL} from './constants';
import EmailModal from './views/EmailModal';
function Login() {

  const navigate = useNavigate();

  // const handleButtonClick = () => {
  //   // Use history.push to navigate to a different route
  //  // navigate('/Admin');
  //  navigate('/ConsultantPortal');
  
  // };

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); 
    
    setTimeout(async () => {
    try {
      const response = await axios.post(BASE_URL+"/login", formData);
  
      // If the login was successful, set the user data
      setUserData(response.data);
      console.log(response.data);
      sessionStorage.setItem('userId' ,response.data.id);
      sessionStorage.setItem('role' ,response.data.role);
      sessionStorage.setItem('username' ,response.data.username);
      sessionStorage.setItem('name' ,response.data.name);
      sessionStorage.setItem('company' ,response.data.company);
      
      
        if(response.data.role === 'ADMIN'){
        navigate('/Admin');
      }else if((response.data.role === 'RECRUITER' || response.data.role === 'CONSULTANT' ) && response.data.pwdexpired === 1){
           
        if(response.data.role === 'RECRUITER' ){
          navigate('/PasswordReset');
        }else if(response.data.role === 'CONSULTANT'){
          
          navigate('/PasswordReset');
        }
      }else if((response.data.role === 'RECRUITER' || response.data.role === 'CONSULTANT' ) && response.data.pwdexpired === 0){
        if(response.data.role === 'RECRUITER' ){
          navigate('/RecDashboard');
        }else if(response.data.role === 'CONSULTANT'){
          
          navigate('/ConsultantPortal');
        }
      }
      setError(null);
    } catch (err) {
      // If there was an error (e.g., user not found), display an error message
      setError('Invalid username or password');
      setUserData(null);
    } finally {
      setLoading(false); // Hide loader when data is received (whether success or error)
    }
  }, 500); // Delay for 500 milliseconds before making the API call
  
  };

  

  return (
    <div className="App">
      {error && <Alert variant="danger">{error}</Alert>}
      
       <div className='login-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
       <div style={{padding: '20px',borderRadius: '10px', border: '1px solid #ccc'}}>

      <div className='text-center'>
        <img src={logo} alt='logo' width={100} height={100} />
        {/* <h1>Login</h1> */}
      </div>

  {/* <EmailModal/> */}
    
      <Form >
        <Form.Group controlId='username'>
          <Form.Control
            type='text'
            name='username'
            value={formData.username}
            onChange={handleChange}
            placeholder='Enter username'
          />
        </Form.Group>
        <br />
        <Form.Group controlId='password'>
          <Form.Control
            type='password'
            name='password'
            value={formData.password}
            onChange={handleChange}
            placeholder='Enter password'
          />
        </Form.Group>
          <br />
        <Stack direction='horizontal' gap={4}>
          <Button
            variant='dark'
            type='submit'
            disabled={loading}
            onClick={handleLogin}
          >
            {loading ? (
              <>
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
                <span className='visually-hidden'>Loading...</span>
              </>
            ) : (
              'Login'
            )}
          </Button>
        </Stack>
      </Form>
      </div>
    </div>
    </div>
  );
}

export default Login;
