import React, { useState, useEffect, useRef } from "react";
import { BASE_URL } from "../constants";
import Table from "react-bootstrap/Table";
import {
  Modal,
  Button,
  Navbar,
  Nav,
  NavDropdown,
  Alert,
  Form,
  Row,
} from "react-bootstrap";
import axios from 'axios';
// import { Document, Page, pdfjs } from 'react-pdf';
import iqubelogo from "../iqube.png";
import dotlogo from '../dot-it-logo.png'
import dotSignature from '../signature.png';
import iqubeSignature from  '../signature.png';

import {
  TELEPHONIC_INTERVIEW_TEMPLATE,
  F2F_INTERVIEW_TEMPLATE,
  VIDEO_INTERVIEW_TEMPLATE,
  HR_INTERVIEW_TEMPLATE,
  DOTIT,IQUBE,VRKIT
} from "../constants";
import PrintTable from "./PrintTable";
import MainNavbar from "./MainNavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const ApplicationsList = () => {
  const [userName, setUserName] = useState("");
  const [applications, setApplications] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // const [numPages, setNumPages] = useState(null);
  const [interviewDate, setInterviewDate] = useState("");
  const [interviewFromTime, setInterviewFromTime] = useState("");
  const [interviewToTime, setInterviewToTime] = useState("");
  const [interviewerName, setInterviewerName] = useState("");

  // const [selectedAppName, setSelectedAppName] = useState('');
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [interviewRound, setInterviewRound] = useState("Technical Telephonic");
  const [interviewScheduleModal, setInterviewScheduleModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successInterviewMessage, setSuccessInterviewMessage] = useState("");

  const [emailTemplate, setEmailTemplate] = useState(
    TELEPHONIC_INTERVIEW_TEMPLATE
  );
  const [videoLink, setVideoLink] = useState("");

  const [interviewFromTime12Hour, setInterviewFromTime12Hour] = useState("");
  const [interviewToTime12Hour, setInterviewToTime12Hour] = useState("");
  const [showVideoLink, setShowVideoLink] = useState(false);
  const [error, setError] = useState("");
  const [errorResume, setErrorResume] = useState("");

  const [interviewStatus, setInterviewStatus] = useState([]);
  const [result, setResult] = useState(""); // Added Result state

  // New state variables for search functionality
  const [searchInput, setSearchInput] = useState("");
  const [searchRoundInput, setSearchRoundInput] = useState("");

  const [filteredInterviewData, setFilteredInterviewData] = useState([]);
  const [filteredInterviewRoundData, setFilteredInterviewRoundData] = useState(
    []
  );
  const [companyEmail, setCompanyEmail] = useState(null);
  const [compAddress , setCompAddress] = useState(null);
  let logo=null;
  let signature=null;
  const [compPhone , setCompPhone] = useState(null);
  useEffect(() => {
    // pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    // Make API call to get data from Node.js backend
    setUserName(sessionStorage.getItem("name"));
    console.log(sessionStorage.getItem("name"));
    const companyName = sessionStorage.getItem("company"); // Replace this with the actual company name

    if(companyName === 'DOTIT'){
      setCompanyEmail(DOTIT);
      setCompAddress('255 old New Brunswick rd , Ste N222 , Piscatway , NJ 08854');
      signature =dotSignature;
      logo = dotlogo;
    }else if(companyName === 'IQUBE'){
        setCompanyEmail(IQUBE);
        setCompAddress('15 Corporate Pl S , Ste353 , Piscatway , NJ 08854');
        signature =iqubeSignature;
       setCompPhone('732-290-4414X100');
        logo = iqubelogo;
    }else if(companyName === 'VRKIT'){
      setCompanyEmail(VRKIT);
    }
    fetch(BASE_URL + `/api/applications/${companyName}`)
      .then((response) => response.json())
      .then((data) => {
        setFilteredInterviewData(data);
        setApplications(data);
      })
      .catch((error) => console.error("Error fetching data:", error));

    fetch(BASE_URL + `/api/fetchGlobalInterviewStatus/${companyName}`)
      .then((response) => response.json())
      .then((data) => {
        setInterviewStatus(data.interviewStatus);
        setFilteredInterviewRoundData(data.interviewStatus);
      })
      .catch((error) => console.error("Error fetching data:", error));

    // filterInterviewStatus();
  }, []);

  const getLogoByCompany = (companyName) => {
    const logoMappings = {
      'IQUBE': iqubelogo,
      'DOTIT': dotlogo,
      // Add more company names and logo imports as needed
    };

    return logoMappings[companyName] || null;
  };
  const getSignatureByCompany = (companyName) => {
    const logoMappings = {
      'IQUBE': iqubeSignature,
      'DOTIT': dotSignature,
      // Add more company names and logo imports as needed
    };

    return logoMappings[companyName] || null;
  };
  const getCompanyFullName = (companyName) => {
    const logoMappings = {
      'IQUBE': 'IQUBE TECHNOLOGIES',
      'DOTIT': 'DOT IT SOLUTIONS ',
      // Add more company names and logo imports as needed
    };

    return logoMappings[companyName] || null;
  };

  const setInterviewDateFn = (value) => {
    setInterviewDate(value);
    console.log(emailTemplate);
  };
  const filterInterviewStatus = (value) => {
    setSearchInput(value);
    if (value.length === 0) {
      setFilteredInterviewData(applications);
    }

    const data = applications.filter(
      (item) =>
        item.Name.toLowerCase().includes(value.toLowerCase()) ||
        item.EMAIL.toLowerCase().includes(value.toLowerCase())
    );
    console.log("new data", data);
    setFilteredInterviewData(data);
  };
  const filterInterviewRoundStatus = (value) => {
    setSearchRoundInput(value);
    if (value.length === 0) {
      setFilteredInterviewRoundData(interviewStatus);
    }

    const data = interviewStatus.filter(
      (item) =>
        item.NAME.toLowerCase().includes(value.toLowerCase()) ||
        item.EMAIL.toLowerCase().includes(value.toLowerCase())
    );
    console.log("new data", data);
    setFilteredInterviewRoundData(data);
  };
  const [modalVisible , setModalVisible ] =useState(false);
  const [startDate, setStartDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const formatDate = (originalDate) => {
    const dateArray = originalDate.split("-");
    const formattedDate = `${parseInt(dateArray[1])}/${parseInt(
      dateArray[2]
    )}/${dateArray[0]}`;
    return formattedDate;
  };
  const handleGenerateLetter= () =>{
    setModalVisible(true);
  }
  const handleCloseModal = () => {
    setModalVisible(false);
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const handleShowModal = (resumeUrl, application) => {
    const updatedResumeUrl = resumeUrl.replace(/https:/g, "http:");
    setSelectedResume(updatedResumeUrl);
    setSelectedApplication(application);
    setShowModal(true);
  };
  const reportTemplateRef = useRef(null);
  const company = sessionStorage.getItem("company");
  const hrName = sessionStorage.getItem("name");

  const dynamicContent =  `

  <p>Dear ${selectedApplication?.Name},</p>

  <p>We are thrilled to inform you that you have been selected for the position of ${selectedRole} at ${getCompanyFullName(company)}. Your skills and experience stood out among the candidates, and we believe you will be a valuable addition to our team.</p>

  <p>Please find attached your official offer letter outlining the terms and conditions of your employment. We encourage you to carefully review the details, including your start date, annual salary, and other pertinent information.</p>

  <p>If you have any questions or require further clarification, please do not hesitate to reach out to us at anytime. We are here to assist you.</p>

  <p>We look forward to welcoming you to our team and are excited about the contributions we know you will make.</p>

  <p>Congratulations once again!</p>

  <p>Best regards,</p>
 
  <p>${hrName}</p>
  <p>HR</p>
  <p>${getCompanyFullName(company)}</p>
  <p>${companyEmail}</p>`;
  // const fs = require('fs');
  const handleContinue = async() => {
    // Make the div visible temporarily
    setModalVisible(false);
    const hiddenDiv = document.getElementById('mainContainer');
    hiddenDiv.style.display = 'block';
  
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });
   
    // Adding the fonts.
    doc.setFont('Inter-Regular', 'normal');
    doc.setFontSize(40);
    
    const options = {
      html2canvas: { scale: 0.4 },
      margin: [40, 40, 40, 40], // top, right, bottom, left
      callback: function (doc) {
        doc.save('Offer Letter.pdf');
        const pdfBlob = doc.output('blob');
        sendEmail(pdfBlob);
       hiddenDiv.style.display = 'none';
      },
    };
    
    // Your reportTemplateRef.current should contain the HTML content
    doc.html(reportTemplateRef.current, options);
   
  };
  
  // Function to send email
function sendEmail(pdfBlob) {
  const formData = new FormData();
  formData.append('pdf', pdfBlob, 'document.pdf');
  formData.append('company', company);
  formData.append('toEmail', selectedApplication.EMAIL);
  formData.append('content', dynamicContent);
  

  axios.post(BASE_URL + "/api/send-email", formData).then((response) => {
    console.log(response.data);
  }
);
}
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedSalary, setSelectedSalary] = useState('');

  const companies = ['Iqube technologies', 'Dot it solutions', 'Vrk It vision'];
  const salaryOptions = ['60k', '65k', '70k', '75k','80k', '85k', '90k', '95k', '100k','105k','110k','115k','120k','125k','130k'];

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleSalaryChange = (event) => {
    setSelectedSalary(event.target.value);
  };
  const templateChange = (value) => {
    // console.log(DYNAMIC_CONTENT);
    //  console.log(VIDEO_INTERVIEW_TEMPLATE);
    //  console.log(HR_INTERVIEW_TEMPLATE);

    let emailTemplate = "";
    setInterviewRound(value);
    if (value === "Technical Telephonic") {
      emailTemplate = TELEPHONIC_INTERVIEW_TEMPLATE;
      setShowVideoLink(false);
      setEmailTemplate(emailTemplate);
      // setEmailTemplate(TELEPHONIC_INTERVIEW_TEMPLATE);
    } else if (value === "Technical Video") {
      //setEmailTemplate(DYNAMIC_CONTENT);
      emailTemplate = VIDEO_INTERVIEW_TEMPLATE;
      setShowVideoLink(true);
      setEmailTemplate(emailTemplate);
    } else if (value === "Face to Face") {
      emailTemplate = F2F_INTERVIEW_TEMPLATE;
      setShowVideoLink(false);
      setEmailTemplate(emailTemplate);
    } else if (value === "HR Round") {
      emailTemplate = HR_INTERVIEW_TEMPLATE;
      setShowVideoLink(true);
      setEmailTemplate(emailTemplate);
    }
  };
  // const handleCloseModal = () => {
  //   setSelectedResume(null);
  //   setSelectedApplication(null);
  //   setShowModal(false);
  // };

  const handleScheduleInterview = () => {
    const dynamicContent = emailTemplate
      .replace("[Candidate Name]", selectedApplication.Name)
      .replace("[Interview Date]", interviewDate)
      .replace(
        "[Interview Time]",
        `${interviewFromTime12Hour} - ${interviewToTime12Hour}`
      )
      .replace("[Interview Round]", interviewRound)
      .replace("[Interviewer]", interviewerName)
      .replace("[Video Interview Link]", videoLink);
    console.log(dynamicContent);

    selectedApplication.STATUS = "SCHEDULED";

    fetch(BASE_URL + "/api/sendEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: selectedApplication.EMAIL,
        subject: "Interview Scheduled",
        content: dynamicContent,
        data: selectedApplication,
        interviewdate: interviewDate,
        interviewtime: `${interviewFromTime12Hour} - ${interviewToTime12Hour}`,
        round: interviewRound ? interviewRound : "Technical Telephonic",
        status: "INTERVIEW SCHEDULED",
        interviewerName: interviewerName,
        company: sessionStorage.getItem("company"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Email sent successfully:", data);
        setSuccessMessage("Interview Scheduled successfully");
        setInterviewDate("");
        setInterviewFromTime("");
        setInterviewToTime("");
        setInterviewToTime12Hour("");
        setInterviewRound("");
        setInterviewScheduleModal(false);
      })
      .catch((error) => {
        setError("Error sending email:");
        console.error("Error sending email:", error);
      });
    setInterviewScheduleModal(false);
  };

  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setNumPages(numPages);
  // };
  //  const updateEmailTemplate =(value) =>{
  //   setEmailTemplate(value);
  //  }

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":");
    const parsedHours = parseInt(hours, 10);
    const period = parsedHours >= 12 ? "PM" : "AM";
    const hours12 = ((parsedHours + 11) % 12) + 1; // Convert to 12-hour format
    return `${hours12}:${minutes} ${period}`;
  };

  const updateInterviewFromTime = (time24) => {
    setInterviewFromTime(time24);
    const formattedTime = convertTo12HourFormat(time24);
    setInterviewFromTime12Hour(formattedTime);

    // const dynamicContent = emailTemplate
    // .replace('[Candidate Name]', selectedApplication.Name)
    // .replace('[Interview Time]', `${interviewFromTime12Hour} - ${interviewToTime12Hour}`)
    // console.log(dynamicContent);
    // setEmailTemplate(dynamicContent);
  };

  const updateInterviewToTime = (time24) => {
    setInterviewToTime(time24);
    const formattedTime = convertTo12HourFormat(time24);
    setInterviewToTime12Hour(formattedTime);
  };
  const updateAttributeById = (array, idToUpdate, attributeName, newValue) => {
    return array.map((item) => {
      if (item.id === idToUpdate) {
        // Create a new object with the updated attribute
        return {
          ...item,
          [attributeName]: newValue,
        };
      }
      // If the id doesn't match, return the original object
      return item;
    });
  };
  const [selectedResult, setSelectedResult] = useState("");
  const [showFeedBackModal, setShowFeedBackModal] = useState(false);
  const [feedback, setFeedback] = useState("");

  const handleShowFeedBackModal = () => setShowFeedBackModal(true);
  const handleCloseFeedBackModal = () => setShowFeedBackModal(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [id, setId] = useState(0);
  const [jobId, setJobId] = useState("");
  const [round, setRound] = useState("");
  const [name, setName] = useState("");
 
  const updateResultApiCall = async (
    id,
    selectedResult,
    email,
    jobid,
    round,
    name
  ) => {
    setSelectedEmail(email);
    setJobId(jobid);
    setRound(round);
    setName(name);
    setSelectedResult(selectedResult);
    setId(id);
    handleShowFeedBackModal();
  };
  const handleSendEmail = async (content) => {
    let message = "";
    if (content === "Selected") {
      message = `Congratulations ${name}! your interview ${round} for position ${jobId} is cleared. we will let you know the next steps.`;
    } else if (content === "Rejected") {
      message = `Hello ${name} , Sorry to inform you that your ${round} have been rejected for the position ${jobId}.`;
    } else if (content === "Rescheduled") {
      message = `Hello ${name} , your ${round} interview has been rescheduled for the position ${jobId}.`;
    } else if (content === "Cancelled") {
      message = `Hello ${name} , your ${round} interview has been Cancelled for the position ${jobId}.we will let you know the next steps.`;
    }

    try {
      const response = await fetch(BASE_URL + "/api/sendInterviewStatusEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: selectedEmail,
          content: message,
          companyName: company,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.message); // Log success message
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  const handleFeedbackSubmit = async () => {
    console.log(interviewStatus);
    const updatedArray = updateAttributeById(
      interviewStatus,
      id,
      "RESULT",
      selectedResult
    );
    setInterviewStatus(updatedArray);
    const apiUrl = BASE_URL + "/api/updateResult"; // Replace with your actual API endpoint

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers required by your API
        },
        body: JSON.stringify({
          id,
          result: selectedResult,
          feedback: feedback,
        }), // Include id and selectedResult in the body
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      // If you need the response data, you can parse it
      const data = await response.json();
      console.log("API call response:", data);

      if (data.message) {
        const companyName = sessionStorage.getItem("company");
        fetch(BASE_URL + `/api/fetchGlobalInterviewStatus/${companyName}`)
          .then((response) => response.json())
          .then((data) => {
            handleCloseFeedBackModal();
            handleSendEmail(selectedResult);
            setInterviewStatus(data.interviewStatus);
            setFilteredInterviewRoundData(data.interviewStatus);
          })
          .catch((error) => console.error("Error fetching data:", error));
      }

      setSuccessInterviewMessage(data.message);
      // You can return the data if needed
      return data;
    } catch (error) {
      setErrorResume(error);
      console.error("API call error:", error);
      throw error; // You might want to handle the error appropriately
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return today.toLocaleDateString('en-US', options);
  };

  const printData = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(
      "<html><head><title>Inteviews Data</title></head><body>"
    );

    printWindow.document.write(
      '<h1 style="text-align: center;color: #3498db;">Inteviews Data</h1>'
    );
    printWindow.document.write('<table border="1">');
    printWindow.document.write("<style>");
    printWindow.document.write(
      "table { border-collapse: collapse; width: 100%; }"
    );
    printWindow.document.write(
      "th, td { border: 1px solid #dddddd; text-align: left; padding: 8px; }"
    );
    printWindow.document.write(
      "th { background-color: #3498db; color: white; }"
    ); // Color added to the header
    printWindow.document.write("</style>");
    printWindow.document.write(
      "<thead><tr><th>Name</th><th>Interview Date</th><th>Interview Time</th><th>Email</th><th>Phone</th><th>Resume</th><th>Round</th><th>Result</th><th>Interviewer</th><th>Feedback</th></tr></thead><tbody>"
    );

    filteredInterviewRoundData.forEach((row) => {
      printWindow.document
        .write(`<tr><td>${row.NAME}</td><td>${row.INTERVIEW_DATE}</td><td>${row.INTERVIEW_TIME}</td><td>${row.EMAIL}</td><td>${row.PHONE}</td><td>${row.RESUME}</td><td>${row.ROUND}</td><td>${row.RESULT}</td><td>${row.INTERVIEWER_NAME}</td>
      <td>${row.FEEDBACK}</td></tr>`);
    });

    printWindow.document.write("</tbody></table>");
    printWindow.document.write(
      '<script type="text/javascript">window.print();</script>'
    );
    printWindow.document.write("</body></html>");
  };
  return (
    <div>
      <MainNavbar userName={userName} />
      {/* <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#/Admin">  <img src={logo} alt='logo' width={80} height={80} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {userName && (
            <NavDropdown title={`Welcome, ${userName}`} id="basic-nav-dropdown">
            
              <NavDropdown.Item href="#/RecruiterStatusTrack">RecruiterStatusTrack</NavDropdown.Item>
              <NavDropdown.Item href="#/BulkMail">Send Hotlist</NavDropdown.Item>
              <NavDropdown.Item href="#/Applications">Applications</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/">Logout</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar> */}

      <div className="container">
        <h2 className="mb-4">
          Total Applications received : {filteredInterviewData.length}
        </h2>

        {error && <Alert variant="danger">{error}</Alert>}
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        <Form.Group controlId="searchInput">
          <Form.Control
            type="text"
            placeholder="Search by Name or Email"
            value={searchInput}
            onChange={(e) => filterInterviewStatus(e.target.value)}
          />
        </Form.Group>
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          <Table striped bordered>
            <thead
              style={{
                position: "sticky",
                top: 0,
                background: "white",
                zIndex: 1,
              }}
            >
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Email</th>
                <th>Phone</th>
                <th>JobId</th>
                <th>Resume</th>
                <th>Status</th>
                <th>Actions</th>
                <th>Offer</th>
              </tr>
            </thead>
            <tbody>
              {filteredInterviewData.map((application) => (
                <tr key={application.id}>
                  <td>{application.Name}</td>
                  <td>{application.Date}</td>
                  <td>{application.EMAIL}</td>
                  <td>{application.PHONE}</td>
                  <td>{application.JOBID}</td>
                  <td>
                    {application.RESUME && (
                      <a
                        variant="success"
                        className="ml-2"
                        href={application.RESUME}
                      >
                        Download
                      </a>
                    )}
                  </td>
                  <td>{application.STATUS}</td>
                  <td>
                    {/* <Button
                    variant="primary"
                    onClick={() => {
                      handleShowModal(application.RESUME, application);
                    }}
                  >
                    View Resume
                  </Button> */}
                    <Button
                      variant="success"
                      className="ml-2"
                      onClick={() => {
                        setSelectedApplication(application);
                      
                        setInterviewScheduleModal(true);
                      }}
                    >
                      Schedule Interview
                    </Button>
                  </td>
                  <td>
                    <button onClick={() => {
                        setSelectedApplication(application);
                        handleGenerateLetter();
                      }}>
                      Generate Offer Letter
                    </button>
                  </td>

                </tr>
              ))}
            </tbody>
          </Table>
          <Modal show={modalVisible} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="startDate">
            <Form.Label>Start Date:</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </Form.Group>

          <Form.Group controlId="role">
            <Form.Label>Select Role:</Form.Label>
            <Form.Control
              as="select"
              value={selectedRole}
              onChange={handleRoleChange}
            >
              <option value="">Select a role</option>
              <option value="Software Developer">Software Developer</option>
              <option value="Software Engineer">Software Engineer</option>
            </Form.Control>
          </Form.Group>
          {/* <Form.Group controlId="company">
            <Form.Label>Select Company:</Form.Label>
            <Form.Control
              as="select"
              value={selectedCompany}
              onChange={handleCompanyChange}
            >
              <option value="">Select a company</option>
              {companies.map((company) => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </Form.Control>
          </Form.Group> */}

          <Form.Group controlId="salary">
            <Form.Label>Select Salary:</Form.Label>
            <Form.Control
              as="select"
              value={selectedSalary}
              onChange={handleSalaryChange}
            >
              <option value="">Select a salary</option>
              {salaryOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleContinue}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
        <div>
          <h2 className="mb-4">Interview Status</h2>
          {errorResume && <Alert variant="danger">{errorResume}</Alert>}
          {successInterviewMessage && (
            <Alert variant="success">{successInterviewMessage}</Alert>
          )}
        </div>
        {filteredInterviewRoundData.length === 0 && (
          <div>No interviews scheduled.</div>
        )}
        {filteredInterviewRoundData.length > 0 && (
          <div>
            <Form.Group controlId="searchInput" className="d-flex">
              <Form.Control
                type="text"
                placeholder="Search by Name or Email"
                value={searchRoundInput}
                onChange={(e) => filterInterviewRoundStatus(e.target.value)}
                className="mr-2"
              />
              <Button onClick={printData}>
                <FontAwesomeIcon icon={faPrint} className="mr-1" />
              </Button>
            </Form.Group>
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              <Table striped bordered>
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "white",
                    zIndex: 1,
                  }}
                >
                  <tr>
                    <th>Name</th>
                    <th>Interview Date</th>
                    <th>Interview Time</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Jobid</th>
                    <th>Resume</th>
                    <th>Round</th>
                    <th>Result</th>
                    <th>Interviewer</th>
                    <th>FeedBack</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredInterviewRoundData.map((status) => (
                    <tr key={status.id}>
                      <td>{status.NAME}</td>
                      <td>{status.INTERVIEW_DATE}</td>
                      <td>{status.INTERVIEW_TIME}</td>
                      <td>{status.EMAIL}</td>
                      <td>{status.PHONE}</td>
                      <td>{status.JOBID}</td>
                      <td>
                        {status.RESUME && (
                          <a
                            variant="success"
                            className="ml-2"
                            href={status.RESUME}
                          >
                            Download
                          </a>
                        )}
                      </td>

                      <td>{status.ROUND}</td>
                      <td>
                        <select
                          className="form-control"
                          value={status.RESULT}
                          onChange={(e) =>
                            updateResultApiCall(
                              status.id,
                              e.target.value,
                              status.EMAIL,
                              status.JOBID,
                              status.ROUND,
                              status.NAME
                            )
                          }
                          style={{
                            backgroundColor:
                              status.RESULT === "Selected"
                                ? "green"
                                : status.RESULT === "Rejected"
                                ? "red"
                                : status.RESULT === "Cancelled"
                                ? "gray"
                                : status.RESULT === "Rescheduled"
                                ? "orange"
                                : "",
                          }}
                        >
                          <option value="Selected">Select</option>
                          <option value="Selected">Selected</option>
                          <option value="Rejected">Rejected</option>
                          <option value="Cancelled">Cancelled</option>
                          <option value="Rescheduled">Rescheduled</option>
                        </select>
                      </td>
                      <td>{status.INTERVIEWER_NAME}</td>
                      <td>{status.FEEDBACK}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}
        {/* <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Resume Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedResume ? (
              <Document file={selectedResume} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from({ length: numPages }).map((_, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            ) : (
              <p>No resume available for preview.</p>
            )}
            {numPages && (
              <p>
                Page {pageNumber} of {numPages}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
        <Modal show={showFeedBackModal} onHide={handleCloseFeedBackModal}>
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="feedbackTextArea">
              <Form.Label>Provide Feedback:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseFeedBackModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFeedbackSubmit}>
              Submit Feedback
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={interviewScheduleModal}
          onHide={() => setInterviewScheduleModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Schedule Interview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Select Interview Round:</label>
            <select
              className="form-control"
              value={interviewRound}
              onChange={(e) => templateChange(e.target.value)}
            >
              <option value="Technical Telephonic">Technical Telephonic</option>
              <option value="Technical Video">Technical Video</option>
              <option value="Face to Face">F2F</option>
              <option value="HR Round">HR Round</option>
            </select>
            <label>Interview Date:</label>
            <input
              type="date"
              className="form-control"
              value={interviewDate}
              onChange={(e) => setInterviewDateFn(e.target.value)}
            />
            <label>Interview Time From:</label>
            <input
              type="time"
              className="form-control"
              value={interviewFromTime}
              onChange={(e) => updateInterviewFromTime(e.target.value)}
            />
            <label>Interview Time To:</label>
            <input
              type="time"
              className="form-control"
              value={interviewToTime}
              onChange={(e) => updateInterviewToTime(e.target.value)}
            />
            <label>Interviewer Name:</label>
            <input
              type="text"
              className="form-control"
              value={interviewerName}
              onChange={(e) => setInterviewerName(e.target.value)}
            />
            {showVideoLink && (
              <div>
                <label>Video link:</label>
                <textarea
                  className="form-control"
                  rows="4"
                  value={videoLink}
                  onChange={(e) => setVideoLink(e.target.value)}
                ></textarea>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setInterviewScheduleModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleScheduleInterview}>
              Schedule
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div id="mainContainer" style={{display: 'none'}}  ref={reportTemplateRef}>
        <div >
          <div class="header">
            <img
              src={getLogoByCompany(company)}
              width={300}
              height={150}
              alt="Company Logo"
              class="company-logo"
            />
          </div>
          
          <div class="center-div">
          <h2>Offer Letter</h2>  
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          
          <p class="terms-conditions">Date: {getCurrentDate()}</p>
      </div>
        
    </div>
         
          <div class="offer-details">
            <span>Dear </span><span>{selectedApplication?.Name},</span>
           
            <p>
              We are pleased to offer you the position of {selectedRole} at {""}
             <strong>{getCompanyFullName(company)}</strong>. Your {""} tentative start date is <br/> scheduled for {formatDate(startDate)}
              {""} and your annual salary will be {selectedSalary}.
            </p>
          </div>

          <div class="terms-conditions">
            <p><strong>Terms and Conditions:</strong></p>
            <p>
              This offer is contingent upon the successful completion of
              background checks and any other pre-employment<br/> requirements.You will be eligible for Company Benefits and will be required
              to comply with Company Policies.
            </p>
            <p>
              
            </p>
          </div>

          <div class="closing">
            <p>
              Please review this offer letter carefully. If you have any
              questions, feel free to contact us at {companyEmail}.
            </p>
            <p>We look forward to welcoming you to our team.<br/><br/></p>
          </div>

          <div class="closing">
            <p>Regards</p>
            <img className="signature" src={getSignatureByCompany(company)} alt='logo' width={100} height={100} />
            <p>{hrName}</p>
            <p>HR</p>
            <p>{selectedCompany}</p>
            <p>Phone: {compPhone}</p>
            <p>Email: {companyEmail}</p>
            <p>Address: {compAddress}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationsList;
