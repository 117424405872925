// src/SubmissionDetails.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button, Col, Row, Table, Modal, Spinner, Alert } from 'react-bootstrap';

import {BASE_URL} from '../constants';
import axios from 'axios';
import Select from 'react-select';

// const consultantsData = [
//   { id: 1, name: 'Shiva Gade', technology: 'Java' },
//   { id: 2, name: 'Karthik Sidda', technology: 'Java' },
//   // Add more consultants here as needed
// ];

const SubmissionDetails = () => {
    const [startDate, setStartDate] = useState('');
    const [consultantsData, setConsultantsData] = useState([]);
    const [error, setError] = useState(null);
  const [endDate, setEndDate] = useState('');
  const [selectedConsultantId, setSelectedConsultantId] = useState('');
  const [submissionData, setSubmissionData] = useState([]);
  const recruiterId= sessionStorage.getItem('userId');
  const [successMessage, setSuccessMessage] = useState('');

  const company = sessionStorage.getItem('company');

  const modalStyles = {
    content: {
      width: '500px',    // Set the desired width
      height: '500px',   // Set the desired height
      margin: 'auto',    // Center the modal horizontally
    },
  };

  useEffect(() => {
        
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL+`/recAndAssignedCons/${recruiterId}/${company}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
      //  setData(result);
      console.log('recruiters data',result);
        setConsultantsData(result);
  
      } catch (err) {
       // setError(err);
       
      }
    };

    fetchData();

  }, [recruiterId]);
  const handleFilter = async () => {
   

    try {
        const response = await fetch(BASE_URL+`/getSubmissionDetails?consultantid=${selectedConsultantId}&recruiterid=${recruiterId}&startdate=${startDate}&enddate=${endDate}&company=${company}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setSubmissionData(result);
        console.log(result);
       // setIsLoading(false);
      } catch (err) {
       // setError(err);
       // setIsLoading(false);
      }
  };
 const [open , setOpen]=useState(false);
const [selectedJD , setSelectedJD]= useState(null);
  const openModal = (jd) => {
    setSelectedJD(jd);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditClick = (submission) => {
    setEditData(submission);
   console.log(showEditModal);
   setShowEditModal(true);
  };
  const [editData, setEditData] = useState(null);

  const [editRow, setEditRow] = useState(null); // To track the row being edited
  
  const consultantOptions = submissionData.map((submission) => ({
    value: submission.id,
    label: submission.consultantname,
  }));
  const [loading, setLoading] = useState(false);

  const handleEditFormSubmit = async (editedData) => {
    setLoading(true);
    try {
          console.log(editData);
          let reqData={
            id: editData.id,
            date: editData.dateofsubmission,
            vendor: editData.vendorname,
            vendorEmail: editData.vendoremail,
            vendorPhone: editData.vendorphone,
            experience: editData.totalexperience,
            workType: editData.worktype,
            rate:editData.rate,
            clientLocation: editData.clientlocation,
            role: editData.role,
            clientName: editData.clientname,
            jobdescription: editData.jobdescription,
            comments: editData.comments,
            consultantid: editData.consultantid,
            consultantname: editData.consultantname,
            recruitid: sessionStorage.getItem('userId'),
            recruitername:sessionStorage.getItem('name'),
            company:sessionStorage.getItem('company'),
          };
          console.log(reqData);
          setTimeout(async () => {
        axios.post(BASE_URL+"/updateSubmission", reqData).then((response) => {
          console.log(response.data);
          setSuccessMessage(response.data.message);
          setLoading(false)
      
        });
     
        // setSubmissionData(updatedData);
        setShowEditModal(false);
      },500);
     
        // Handle API error, show a message, etc.
      
    } catch (error) {
      setLoading(false)
      // Handle API error, show a message, etc.
    }
  };

const [showInterviewModal, setShowInterviewModal] = useState(false);
const [selectedSubmission, setSelectedSubmission] = useState(null);
const [jobid, setJobId] = useState(0);
 const [interviewData, setInterviewData] = useState({
  jobid:'',
  date: '',
  time: '',
  mode: '',
  round: '',
  details:'',
  jd:'',
  vendor:'',
  vendoremail:'',
  clientname:'',
  clientlocation:'',
  recruitid :'',
  consultantid:''
});

const handleScheduleInterview = (submission) => {
  setSelectedSubmission(submission);
  console.log(submission);
  setShowInterviewModal(true);
};

const handleInterviewFormSubmit = async(e) => {
  interviewData.jobid= selectedSubmission.id;
   interviewData.jd= selectedSubmission.jobdescription;
   interviewData.clientlocation= selectedSubmission.clientlocation;
   interviewData.clientname= selectedSubmission.clientname;
   interviewData.vendor= selectedSubmission.vendorname;
   interviewData.vendoremail= selectedSubmission.vendoremail;
   interviewData.recruitid=sessionStorage.getItem('userId');
   interviewData.consultantid=selectedConsultantId;
   interviewData.recruitername=selectedSubmission.recruitername;
   console.log(interviewData);
    e.preventDefault();

    try {
      const response = await fetch(BASE_URL+'/interviewschedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(interviewData),
      });

      if (response.status === 200) {
        console.log('Data inserted successfully');
      } else {
        console.error('Error inserting data');
      }
    } catch (error) {
      console.error('Error:', error);
    }

  setShowInterviewModal(false);
};

const closeInterviewModal = () => {
  setShowInterviewModal(false);
};

const [apiData, setApiData] = useState([]);
const [statusModal, setStatusModal] = useState(false);
const [selectedStatus, setSelectedStatus] = useState('selected');


const [status, setStatus] = useState('selected');

const handleSave = (item) => {
  setStatusModal(true);
  //setSelectedStatus('selected'); // Set the default selected status
  const data={
    round: item.round,
    status: selectedStatus,
    jobid: item.jobid
  }
   console.log(data);
  // Additional logic as needed

  axios.post(BASE_URL + `/updateInterviewSchedule`, data)
    .then((response) => {
      console.log('Data saved successfully:', response.data);
      setSuccessMessage('Interview status saved successfully');
     // onHide(); // Close the modal
    })
    .catch((error) => {
      console.error('Error saving data:', error);
      //onHide(); // Close the modal in case of an error
    });
};



const closeStatusModal = () => {
  setStatusModal(false);
};

const fetchDataFromAPI = (submission) => {
  // Replace with your API endpoint
  axios.get(BASE_URL+`/interviewschedule/${submission.id}`)
    .then((response) => {
      setStatusModal(true);
      setApiData(response.data);
    
    })
    .catch((error) => {
      console.error('Error fetching data: ' + error);
    });
};
  return (
    <div>
      <br />
       {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <h2>Submission Details</h2>
      <Modal show={statusModal} onHide={closeStatusModal}>
        <Modal.Header closeButton>
          <Modal.Title>Interveiws Status</Modal.Title>
         
        </Modal.Header>
        <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <Table>
            <thead>
              <tr>
              <th>Round</th>
                <th>Mode</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {apiData.map((item, index) => (
                <tr key={index}>
                    <td>{item.round}</td>
                  <td>{item.mode}</td>
                  <td>
                    <Form.Select
                      value={item.status}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                      <option value="selected">Selected</option>
                      <option value="rejected">Rejected</option>
                    </Form.Select>
                  </td>
                  <td>
                  <Button variant="primary" onClick={() => handleSave(item)}>
  Save
</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeStatusModal}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
      <Modal show={showInterviewModal} onHide={() => setShowInterviewModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Schedule Interview</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="form-group">
        <label htmlFor="interviewDate">Interview Date</label>
        <input
          type="date"
          className="form-control"
          id="interviewDate"
          name="interviewDate"
          value={interviewData.date}
          onChange={(e) => setInterviewData({ ...interviewData, date: e.target.value })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="interviewTime">Interview Time</label>
        <input
          type="time"
          className="form-control"
          id="interviewTime"
          name="interviewTime"
          value={interviewData.time}
          onChange={(e) => setInterviewData({ ...interviewData, time: e.target.value })}
        />
      </div>

  <div className="form-group">
  <label htmlFor="interviewMode">Mode of Interview</label>
  <Select
    options={[
      { value: 'telephonic', label: 'Telephonic' },
      { value: 'face2face', label: 'Face to Face' },
      { value: 'video', label: 'Video' },
      // Add more mode options as needed
    ]}
    value={
      interviewData.mode
        ? { value: interviewData.mode, label: interviewData.mode }
        : null
    }
    onChange={(selectedOption) =>
      setInterviewData({ ...interviewData, mode: selectedOption.value })
    }
  />
</div>

      <div className="form-group">
  <label htmlFor="interviewRound">Interview Round</label>
  <Select
    options={[
      { value: 'Assesment', label: 'Assesment' },
      { value: 'Round 1', label: 'Round 1' },
      { value: 'Round 2', label: 'Round 2' },
      { value: 'Round 3', label: 'Round 3' },
      // Add more round options as needed
    ]}
    value={
      interviewData.round
        ? { value: interviewData.round, label: interviewData.round }
        : null
    }
    onChange={(selectedOption) =>
      setInterviewData({ ...interviewData, round: selectedOption.value })
    }
  />
</div>
<div className="form-group">
        <label htmlFor="interviewDetails">Interview Details</label>
        <textarea
          className="form-control"
          id="interviewDetails"
          name="interviewDetails"
          value={interviewData.details}
          onChange={(e) => setInterviewData({ ...interviewData, details: e.target.value })}
          rows="4"
          placeholder="Enter interview details..."
        />
      </div>

      {/* Other form fields for feedback and status go here */}
      
      <div className="button-container">
  <Button variant="primary" onClick={handleInterviewFormSubmit}>
    Save
  </Button>
  <Button variant="secondary" onClick={closeInterviewModal}>
    Cancel
  </Button>
</div>
    </form>
  </Modal.Body>
</Modal>

      <div className="mb-3">
        <Form>
          <Row>
            <Col md={4}>
              <Form.Group controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="consultant">
                <Form.Label>Select Consultant</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedConsultantId}
                  onChange={(e) => setSelectedConsultantId(e.target.value)}
                >
                  <option value="">Select Consultant</option>
                  {consultantsData.map((consultant) => (
                    <option key={consultant.id} value={consultant.id}>
                      {consultant.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Button
            variant="dark"
            type="button"
            onClick={handleFilter}
          >
            Filter Submissions
          </Button>
        </Form>
      </div>
      <div>
        <h3>Filtered Submission Data</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th></th>
              <th>Schedule Interview</th>
              <th>Interview Status</th>
              <th>Consultant Name</th>
              <th>Date</th>
              <th>Vendor Name</th>
              <th>Vendor Email</th>
              <th>Vendor Phone</th>
              <th>Experience (Years)</th>
              <th>Work Type</th>
              <th>Rate</th>
              <th>Client Name</th>
              <th>Client Location</th>
              <th>Role</th>
              <th>Job Desc</th>
            </tr>
          </thead>
          <tbody>
            {submissionData.map((submission) => (
              <tr key={submission.id}>
         
                 <td>
                <Button variant="primary"  onClick={() => handleEditClick(submission)}>Edit</Button>

        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group">
            <label htmlFor="consultantName">Consultant Name</label>
           
            <Select
              className="form-control"
              id="consultantName"
              name="consultantName"
              value={editData ? { value: editData.id, label: editData.consultantname } : null}
              onChange={(selectedOption) => {
                setEditData({
                  id: selectedOption.value,
                  consultantName: selectedOption.label,
                });
              }}
              options={consultantOptions}
            />
          </div>
            <div className="form-group">
              <label htmlFor="date">Date of Submission</label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                value={editData ? editData.dateofsubmission : ''}
                onChange={(e) => setEditData({ ...editData, dateofsubmission: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="vendor">Name of Vendor</label>
              <input
                type="text"
                className="form-control"
                id="vendor"
                name="vendor"
                value={editData ? editData.vendorname : ''}
                onChange={(e) => setEditData({ ...editData, vendorname: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="vendorEmail">Vendor Email</label>
              <input
                type="text"
                className="form-control"
                id="vendorEmail"
                name="vendorEmail"
                value={editData ? editData.vendoremail : ''}
                onChange={(e) => setEditData({ ...editData, vendoremail: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="vendorPhone">Vendor Phone</label>
              <input
                type="text"
                className="form-control"
                id="vendorPhone"
                name="vendorPhone"
                value={editData ? editData.vendorphone : ''}
                onChange={(e) => setEditData({ ...editData, vendorphone: e.target.value })}/>
            </div>
            <div className="form-group">
              <label htmlFor="totalExperience">Total Experience (Years)</label>
              <input
                type="number"
                className="form-control"
                id="totalExperience"
                name="totalExperience"
                value={editData ? editData.totalexperience : ''}
                onChange={(e) => setEditData({ ...editData, totalexperience: e.target.value })}/>
            </div>
            <div className="form-group">
              <label htmlFor="worktype">Work Type</label>
              <Select
                options={[
                  { value: 'Remote', label: 'Remote' },
                  { value: 'Onsite', label: 'Onsite' },
                  { value: 'Hybrid', label: 'Hybrid' },
                ]}
                value={editData ? { value: editData.worktype, label: editData.worktype } : ''}
                onChange={(selectedOption) => setEditData({ ...editData, worktype: selectedOption.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="rate">Rate</label>
              <input
                type="text"
                className="form-control"
                id="rate"
                name="rate"
                value={editData ? editData.rate : ''}
                onChange={(e) => setEditData({ ...editData, rate: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="role">Role</label>
              <input
                type="text"
                className="form-control"
                id="role"
                name="role"
                value={editData ? editData.role : ''}
                onChange={(e) => setEditData({ ...editData, role: e.target.value })}
              />
            </div>
            <div class="form-group">
                <label htmlFor="jobdescription">Job Description:</label>
                <textarea name='jobdescription'  value={ editData ? editData.jobdescription : ''}  onChange={(e) => setEditData({ ...editData, jobdescription: e.target.value })} class="form-control" id="jobdescription" rows="4" placeholder="Enter JD"></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="clientLocation">Client Location</label>
              <input
                type="text"
                className="form-control"
                id="clientLocation"
                name="clientLocation"
                value={editData ? editData.clientlocation : ''}
                onChange={(e) => setEditData({ ...editData, clientlocation: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="clientName">Client Name</label>
              <input
                type="text"
                className="form-control"
                id="clientName"
                name="clientName"
                value={editData ? editData.clientname : ''}
                onChange={(e) => setEditData({ ...editData, clientname: e.target.value })}
              />
            </div>
            <div class="form-group">
                <label htmlFor="comments">Comments:</label>
                <textarea name='comments'  value={ editData ? editData.comments : ''}  onChange={(e) => setEditData({ ...editData, comments: e.target.value })} class="form-control" id="comments" rows="4" placeholder="Comments"></textarea>
            </div>
           
            
            <br />
            <div style={{display : 'flex', gap: '1rem'}}>
  <Button variant="primary" onClick={() => handleEditFormSubmit(editData)}>
  {loading ? (
            <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
            </>
          ) : (
            'Save'
          )}
  </Button>

  <Button variant="secondary" onClick={() => setShowEditModal(false)}>
    Cancel
  </Button>
</div>
              </form>
         </Modal.Body> 
            </Modal>
         
              
              </td>
              <td>
      <Button variant="primary" onClick={() => handleScheduleInterview(submission)}>
        Schedule Interview
      </Button>
    </td>
    <td>
      <Button variant="primary" onClick={() => fetchDataFromAPI(submission)}>
        Interview Status
      </Button>
    </td>
                <td>{submission.consultantname}</td>
                <td>{submission.dateofsubmission}</td>
                <td>{submission.vendorname}</td>
                <td>{submission.vendoremail}</td>
                <td>{submission.vendorphone}</td>
                <td>{submission.totalexperience}</td>
                <td>{submission.worktype}</td>
                <td>{submission.rate}</td>
                <td>{submission.clientname}</td>
                <td>{submission.clientlocation}</td>
                <td>{submission.role}</td>
                <td>
                <a href="#" onClick={(e) => { e.preventDefault(); openModal(submission.jobdescription); }}>
                  View Job Description
                </a>
                <Modal style={modalStyles} show={open} onRequestClose={() => closeModal()}>
                  <h2>Job Description</h2>
                  <p>{selectedJD}</p>
        <Button
            variant="dark"
            type="button"
            onClick={() => closeModal()}
          >Close</Button>
                  
                </Modal>
              </td>
              </tr>
            ))}
          </tbody>
        </Table>
        
        {submissionData.length ===0 && <h3>no results found.</h3>}

      </div>
    </div>
  );
};

export default SubmissionDetails;
