import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';

const DateFilter = ({ onSubmit }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleFilter = () => {
    onSubmit(startDate, endDate);
  };

  return (
    <Container>
      <h2>Submission Details</h2>
      <Row className="mb-3">
        <Col md={4}>
          <Form.Group controlId="startDate">
            <Form.Label>From Date</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="endDate">
            <Form.Label>To Date</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Button
            variant="dark"
            type="button"
            onClick={handleFilter}
          >
            Filter Submissions
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default DateFilter;
